define('m20/components/extrusio-n', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Extrusion = Ember['default'].Component.extend({
    tagName: 'extrusion',
    attributeBindings: ['endCap', 'solid', 'creaseAngle', 'crosssection', 'orientation', 'scale', 'spine'],
    endCap: true,
    solid: true,
    ispickable: "0",
    creaseAngle: 7,

    //crossection beschreibt die zu extrodierende Grundform in der xz-Ebene
    crosssection: (function () {
      var name = this.get('name');
      var m06 = this.get('model');
      var bauteile = m06.objectAt(0).get('bauteile');

      var item = bauteile.findBy('id', name);

      var values = false;
      var x;
      var y;
      var z;

      if (item.get('typ') === "box") {
        x = item.get('boxsizes').objectAt(0).get('x') / 10;
        z = item.get('boxsizes').objectAt(0).get('z') / 10;
        y = item.get('boxsizes').objectAt(0).get('y') / 10;

        if (name === 'hauptTraeger') {

          item.get('boxsizes').objectAt(0).set('z', z * 10);

          if (m06.objectAt(0).get('istHt') === false) {
            x = item.get('boxsizes').objectAt(0).get('y') / 10;
            z = item.get('boxsizes').objectAt(0).get('x') / 10;
          }
          values = -x / 2 + ',' + -z / 2 + '),(' + -x / 2 + ',' + z / 2 + '),(' + x / 2 + ',' + z / 2 + '),(' + x / 2 + ',' + -z / 2 + '),(' + -x / 2 + ',' + -z / 2;
        } else if (name === 'nebenTraeger') {
          var hwinkel = Number(m06.objectAt(0).get('hWinkel'));
          var vwinkel = 0 + Number(m06.objectAt(0).get('vWinkel'));

          hwinkel = hwinkel * Math.PI / 180;
          vwinkel = vwinkel * Math.PI / 180;

          var deltaZ = x * Math.tan(vwinkel) * Math.tan(90 * Math.PI / 180 - hwinkel);

          x = x / Math.cos(vwinkel);
          z = z / Math.sin(hwinkel);

          values = '[(' + -x / 2 + ',' + (-z / 2 + deltaZ) + '),(' + -x / 2 + ',' + (z / 2 + deltaZ) + '),(' + x / 2 + ',' + z / 2 + '),(' + x / 2 + ',' + -z / 2 + '),(' + -x / 2 + ',' + (-z / 2 + deltaZ) + ')]';
        }
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt'),

    //orientation beschreibt die Richtung in der die Extrusion erfolgt
    //Wird die Extrusion in mehreren Schritten/Stufen durchgeführt, ist die Richtung für jede Stufe anzugeben.
    orientation: (function () {
      var name = this.get('name');
      var m06 = this.get('model');
      var bauteile = m06.objectAt(0).get('bauteile');
      var item = bauteile.findBy('id', name);

      var values = '0 1 0 0, 0 1 0 0';

      if (item.get('typ') === "box") {
        values = '0 1 0 0, 0 1 0 0';
      }

      return values;
    }).property('model.firstObject.transformHelper'),

    //scale beschreibt die Skalierung der Grundfläche während eines Extrusionschritts
    //Wird die Extrusion in mehreren Schritten/Stufen durchgeführt, ist die Skalierung für jeden Schritt/Stufe anzugeben.
    scale: (function () {
      var name = this.get('name');
      var m06 = this.get('model');
      var bauteile = m06.objectAt(0).get('bauteile');

      var item = bauteile.findBy('id', name);

      var values = '0 0, 0.025 0.025, 0.05 0.05, 0.07 0.07';

      if (item.get('typ') === "box") {
        values = '1 1, 1 1';
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt'),

    //spine beschreibt die Länge der Extrusion in y-Richtung
    //Die Extrusion kann in mehreren Schritten/Stufen erfolgen
    spine: (function () {
      var name = this.get('name');
      var m06 = this.get('model');
      var bauteile = m06.objectAt(0).get('bauteile');
      var values = '0 0 0';

      var item = bauteile.findBy('id', name);

      if (item.get('typ') === "box") {
        if (name === 'hauptTraeger') {
          var y = item.get('boxsizes').objectAt(0).get('y') / 10;
          if (m06.objectAt(0).get('istHt') === false) {
            y = item.get('boxsizes').objectAt(0).get('z') / 10;
          }
          values = '[(0,0,0), (0 ' + y + ' 0)]';
        }
        if (name === 'nebenTraeger') {

          y = item.get('boxsizes').objectAt(0).get('y') / 10;

          var hwinkel = Number(m06.objectAt(0).get('hWinkel'));
          var vwinkel = 0 + Number(m06.objectAt(0).get('vWinkel'));

          hwinkel = hwinkel * Math.PI / 180;
          vwinkel = vwinkel * Math.PI / 180;

          var x = 0 - y * Math.tan(vwinkel);
          var z = y / Math.tan(hwinkel);

          values = '[(0,0,0), (' + x + ' ' + y + ' ' + z + ')]';
        }
      }

      return values;
    }).property('model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.transformHelper', 'model.firstObject.istHt')
  });

  exports['default'] = Extrusion;

});