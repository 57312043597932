define('m20/controllers/supercontroller', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  Ember['default'].deprecate = function () {};var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Controller.extend({

    i18n: Ember['default'].inject.service(),

    uebergabedatenErzeugen: function uebergabedatenErzeugen() {
      var self = this;

      var self = this;

      self.transitionToRoute('downloadcenter');
      var dc = self.controllerFor('downloadcenter');
      dc.set('savingInProgress', true);

      var application = self.controllerFor('application');

      var applicationdata = application.get('model').application.objectAt(0);
      var applicationdataJSON = JSON.stringify(applicationdata);

      var projektdatendata = this.controllerFor('application').get('model').projektdaten.objectAt(0);
      var projektdatendataJSON = JSON.stringify(projektdatendata);

      var bauteiledata = this.controllerFor('application').get('model').bauteile.objectAt(0);
      var bauteiledataJSON = JSON.stringify(bauteiledata);

      var anschlussgeometriedata = this.controllerFor('application').get('model').anschlussgeometrie.objectAt(0);
      var anschlussgeometriedataJSON = JSON.stringify(anschlussgeometriedata);

      var lasteinwirkungdata = this.controllerFor('application').get('model').lasteinwirkung.objectAt(0);
      var lasteinwirkungdataJSON = JSON.stringify(lasteinwirkungdata);

      if (self.debug) {
        console.log("projektdatendata: " + projektdatendataJSON);
        console.log("applicationdataJSON: " + applicationdataJSON);
        console.log("bauteiledataJSON: " + bauteiledataJSON);
        console.log("anschlussgeometriedataJSON: " + anschlussgeometriedataJSON);
        console.log("lasteinwirkungdataJSON: " + lasteinwirkungdataJSON);
      }

      // var projektdatendataJSONcut = projektdatendataJSON.substring(1, projektdatendataJSON.length - 1);
      // var bauteiledataJSONcut = bauteiledataJSON.substring(1, bauteiledataJSON.length - 1);
      // var anschlussgeometriedataJSONcut = anschlussgeometriedataJSON.substring(1, anschlussgeometriedataJSON.length - 1);
      // var lasteinwirkungdataJSONcut = lasteinwirkungdataJSON.substring(1, lasteinwirkungdataJSON.length - 1);
      // var applicationdataJSONcut = applicationdataJSON.substring(1, applicationdataJSON.length - 1);

      // var separators = [':', ','];
      // if (self.debug) {
      //console.log(separators.join('|'));
      // }
      // var bauteildataarray = bauteiledataJSONcut.split(new RegExp(separators.join('|'), 'g'));

      var b = null,
          h = null;

      if (bauteiledata.get('HtRiegel') === true) {
        b = bauteiledata.get('TimberElementHT_b');
        h = bauteiledata.get('TimberElementHT_h');
      } else {
        b = bauteiledata.get('TimberElementHT_h');
        h = bauteiledata.get('TimberElementHT_b');
      }

      var TimberElementHT = {
        "Querschnittswerte": {
          "QuerschnittsID": "1",
          "t_Fastener": b,
          "b": b,
          "h": h
        },
        "alphaRAD_KraftFaser": "0.0",
        "alphaRAD_vmFaser": "90.0",
        "betaRAD_vmFurnier": "90.0",
        "vorgebohrt": bauteiledata.get('TimberElementHT_Predrilled'),
        "MatKey": bauteiledata.get('MatKeyTimberElementHT'),
        "FKLKey": bauteiledata.get('FKLKeyTimberElementHT')
      };

      var TimberElementNT = {
        "Querschnittswerte": {
          "QuerschnittsID": "1",
          "t_Fastener": bauteiledata.get('TimberElementNT_b'),
          "b": bauteiledata.get('TimberElementNT_b'),
          "h": bauteiledata.get('TimberElementNT_h')
        },
        "alphaRAD_KraftFaser": "0.0",
        "alphaRAD_vmFaser": "90.0",
        "betaRAD_vmFurnier": "90.0",
        "vorgebohrt": bauteiledata.get('TimberElementNT_Predrilled'),
        "MatKey": bauteiledata.get('MatKeyTimberElementNT'),
        "FKLKey": bauteiledata.get('FKLKeyTimberElementNT')
      };

      // var timberelementstringht = "\"TimberElementHT\" : { \"Querschnittswerte\" : { \"b\" : " + bauteildataarray[5] + ",  \"h\": " + bauteildataarray[7] + " } },";
      // var timberelementstringnt = "\"TimberElementNT\" : { \"Querschnittswerte\" : { \"b\" : " + bauteildataarray[17] + ",  \"h\": " + bauteildataarray[19] + " } }";
      //
      //
      // var uebergabedaten = "{" + applicationdataJSONcut + "," + projektdatendataJSONcut + "," + bauteiledataJSONcut + "," + timberelementstringht + timberelementstringnt + "," + anschlussgeometriedataJSONcut + "," + lasteinwirkungdataJSONcut + "}";

      var uedaten = $.extend(true, JSON.parse(applicationdataJSON), JSON.parse(projektdatendataJSON), JSON.parse(bauteiledataJSON), JSON.parse(anschlussgeometriedataJSON), JSON.parse(lasteinwirkungdataJSON));
      uedaten.TimberElementHT = TimberElementHT;
      uedaten.TimberElementNT = TimberElementNT;

      return JSON.stringify(uedaten);
    },

    erzeugeAufrufzusatz: function erzeugeAufrufzusatz(zweiterzusatz) {

      // Aufrufe	Zusatz1	Zusatz2
      // initialisiereBerechnung	"?"+kennungland_loadsNA_timberNA
      // getKompaktergebnis	"?"+kennungland_loadsNA_timberNA	"_"+artikelnummer.replace(' ','+')
      // erzeugeSchraubenliste	"?"+kennungland_loadsNA_timberNA
      // erzeugeAusgabe	"?"+kennungland_loadsNA_timberNA	"_"+artikelnummer.replace(' ','+')
      // erzeugeDXF	"?"+kennungland_loadsNA_timberNA	"_"+artikelnummer.replace(' ','+')
      // speichereBerechnung	"?"+kennungland_loadsNA_timberNA
      //
      // Beispiel
      // erzeugeAusgabe/?CH_CH_DE_0170+350+45

      var self = this;

      var application = self.controllerFor('application');
      var applicationdata = application.get('model').application.objectAt(0);
      var verbindungsmittel = self.controllerFor('verbindungsmittel');

      var zusatzString = "?" + applicationdata.get("kennungland") + "_" + applicationdata.get("loadsNA") + "_" + applicationdata.get("timberNA");

      if (zweiterzusatz === true) {

        var artikelnummer;

        if (typeof verbindungsmittel.get('selectedNodes')[0] === 'undefined') {
          artikelnummer = "Keine Artikel ausgewaehlt";
        } else {
          artikelnummer = verbindungsmittel.get('selectedNodes')[0].data.ArtNr;
        }

        zusatzString = zusatzString + "_" + artikelnummer.replace(/ /g, '+');
      }

      return zusatzString;
    },

    getHelpText: function getHelpText(reiter, steuerElemente, bedingung) {
      return hilfeTexteDB_wuerth.reiter.findBy("bezeichnung", reiter).steuerElemente.findBy("bezeichnung", steuerElemente).hilfeTexte.findBy("abhaengigkeit", bedingung).hilfetextfelder;
    },

    setHilfetexte: function setHilfetexte(controllerName, hilfetextArray) {
      var self = this;
      var controller = self.controllerFor(controllerName);
      for (var i = 0; i < hilfetextArray.length; i++) {
        if (hilfetextArray[i].text !== controller.get(hilfetextArray[i].bezeichnung)) {
          controller.set(hilfetextArray[i].bezeichnung + "Focused", true);
        }
        // controller.set(hilfetextArray[i].bezeichnung, hilfetextArray[i].text);
        controller.set(hilfetextArray[i].bezeichnung, self.get('i18n').t(hilfetextArray[i].text.toString()));
      }
    },

    setHilfetexteDisplayed: function setHilfetexteDisplayed(textProperty) {
      var self = this;
      // if(textProperty === "" || typeof self.get('i18n').t(textProperty) === "undefined" || self.get('i18n').t(textProperty) === ""){
      if (textProperty === "" || textProperty.toString().substring(0, 20) === "Missing translation:" || typeof textProperty === "undefined" || self.get('i18n').t(textProperty) === "") {
        return false;
      } else {
        return true;
      }
    },

    logaufruf: function logaufruf(_aufruf, _status, _showOutput, _logAnServerSendenErzwingen) {
      var self = this;
      var application = self.controllerFor('application');
      var applicationdata = application.get('model').application.objectAt(0);

      var date = new Date();
      var datuminticks = date.getTime() * 10000 + 621355968000000000;

      if (_showOutput) {
        console.log("***** logaufruf von *****");
        console.log(_aufruf);
      }

      var tc = application.get('model').application.objectAt(0).get('timberCode').replace(" ", "").substring(0, 3);
      var lc = application.get('model').application.objectAt(0).get('loadsCode').replace(" ", "").substring(0, 3);

      var guid = "00000000-0000-0000-0000-000000000000";
      var guidlength = guid.length;
      var cidlength = application.get('model').application.objectAt(0).get('Calculation_ID').length;
      guid = guid.substring(0, guidlength - cidlength) + application.get('model').application.objectAt(0).get('Calculation_ID');

      var logdaten = {
        "datum": datuminticks,
        "bid": guid,
        "request": 0,
        "firma": enums.Firma.findBy("bezeichnung", application.get('model').application.objectAt(0).get('firm')).id,
        "modul": enums.Modul.findBy("bezeichnung", "m06").id,
        "bemessung": enums.NormBemessung.findBy("bezeichnung", tc).id,
        "bemessungNAD": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('timberNA').toLowerCase()).id,
        "lastannahmen": enums.NormLastannahmen.findBy("bezeichnung", lc).id,
        "lastannahmenNAD": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('loadsNA').toLowerCase()).id,
        "eingabeSprache": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('spracheinput').toLowerCase()).id,
        "ausgabeSprache": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('spracheoutput').toLowerCase()).id,
        "kennungLand": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('kennungland').toLowerCase()).id,
        "katalog": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('katalog').toLowerCase()).id,
        "methode": enums.Methode.findBy("bezeichnung", _aufruf).id,
        "userIp": null,
        "userName": application.get('model').application.objectAt(0).get('userName'),
        "url": null,
        "status": _status,
        "artikelnummer": application.get('model').application.objectAt(0).get('selectedScrew')
      };

      if (_showOutput) {
        console.log("zu lockende Daten:");
        console.log(logdaten);
      }

      var form = new FormData();
      form.append("", JSON.stringify(logdaten));

      var environment = externalConfig.environments[application.get('environment')];
      var url = externalConfig[environment].uriLogservice;

      var liveVersion = application.get('environment').search('dev') !== -1 ? false : true;

      if (liveVersion === true || _logAnServerSendenErzwingen === true) {

        $.ajax({
          "type": "POST",
          "url": url,
          "processData": false,
          "contentType": false,
          data: form

        }).done(function (data, statusText, xhr) {
          if (_showOutput) {
            console.log("Daten gelockt: " + data);
            // var status = xhr.status;                //200
            // console.log("statusText");
            // console.log(statusText);
            // console.log("status");
            // console.log(status);
            console.log(" ");
          }
        });
      }
      // testen von der Browserkonsole --> M20.__container__.lookup('controller:supercontroller').logaufruf('erzeugeDXF', 4444, true, true);
    }

  });

});