define('m20/components/cylinde-r', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Cylinder = Ember['default'].Component.extend({
    tagName: 'cylinder'
  });

  exports['default'] = Cylinder;

});