define('m20/controllers/bauteile', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {
    debug: true,
    openFromFile: false,
    c26: false,
    displayErrors: true,
    material: {},
    honk: null,
    ttHoeheHT: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "10 - 999.9 [cm]";
    }),
    ttBreiteHT: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "5 - 999.9 [cm]";
    }),
    ttHoeheNT: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "10 - 999.9 [cm]";
    }),
    ttBreiteNT: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "5 - 999.9 [cm]";
    }),
    i18n: Ember['default'].inject.service(),
    self: undefined,
    hauptnebentraeger: '',
    htbreite: "",
    hthoehe: "",
    ntbreite: "",
    nthoehe: "",
    toolTip: "test tooltip",
    htfkl: 5,
    ntfkl: 5,
    htholz: 0,
    ntholz: 0,
    httraeger: true,

    validations: {
      htbreite: {
        numericality: {
          greaterThanOrEqualTo: 5,
          lessThanOrEqualTo: 999.9
        }
      },
      hthoehe: {
        numericality: {
          greaterThanOrEqualTo: 10,
          lessThanOrEqualTo: 999.9
        }
      },
      ntbreite: {
        numericality: {
          greaterThanOrEqualTo: 5,
          lessThanOrEqualTo: 999.9
        }
      },
      nthoehe: {
        numericality: {
          greaterThanOrEqualTo: 10,
          lessThanOrEqualTo: 999.9
        }
      }
    },

    traeger: [{
      name: "haupt_nebentraeger",
      id: true
    }, {
      name: "stuetze_nebentraeger",
      id: false
    }],

    contentTraeger: [],

    ntmaterial: [],

    fkl_vh: [],

    fkl_bsh: [],

    fkl_bsh_kombi: [],

    htfklarray: [],
    ntfklarray: [],

    vgebohrt: [],
    te1vorgebohrt: false,
    te1laubholz: false,
    te2vorgebohrt: false,
    te2laubholz: false,

    te1vorgebohrtHilfetext: "",
    te1vorgebohrtHilfetextFocused: false,
    te2vorgebohrtHilfetext: "",
    te2vorgebohrtHilfetextFocused: false,

    init: function init() {
      this._super();
      // this.set('htfklarray', this.fkl_vh);
      // this.set('ntfklarray', this.fkl_vh);

      this.setSelectFieldsContent();

      this.set('hauptnebentraeger', this.get('i18n').t('bemessungslast').toString());
    },

    setValues: function setValues(values) {

      var self = this;

      if (self.debug) {
        console.log("values in bauteile: ");
        console.log(values);
      }

      var htbreiteEvent = { target: { name: "htbreite" } };
      var hthoeheEvent = { target: { name: "hthoehe" } };
      var ntbreiteEvent = { target: { name: "ntbreite" } };
      var nthoeheEvent = { target: { name: "nthoehe" } };

      this.set('openFromFile', true);

      this.set('htholz', parseInt(values.MatKeyTimberElementHT));
      this.set('htfkl', parseInt(values.FKLKeyTimberElementHT));

      this.set('htbreite', values.TimberElementHT_b);
      this.setX3D(parseInt(values.TimberElementHT_b), htbreiteEvent);

      this.set('hthoehe', values.TimberElementHT_h);
      this.setX3D(parseInt(values.TimberElementHT_h), hthoeheEvent);

      this.set('httraeger', values.HtRiegel);

      this.set('ntholz', parseInt(values.MatKeyTimberElementNT));
      this.set('ntfkl', parseInt(values.FKLKeyTimberElementNT));

      this.set('ntbreite', values.TimberElementNT_b);
      this.setX3D(parseInt(values.TimberElementHT_b), ntbreiteEvent);

      this.set('nthoehe', values.TimberElementNT_h);
      this.setX3D(parseInt(values.TimberElementNT_h), nthoeheEvent);

      this.set('te1vorgebohrt', values.TimberElementHT_Predrilled);
      this.set('te2vorgebohrt', values.TimberElementNT_Predrilled);

      this.send('validation');

      this.set('openFromFile', false);
    },

    setSelectFieldsContent: function setSelectFieldsContent() {
      var self = this;
      self.setContentTraeger();
      self.setHtMaterialien();
      self.setHtFKL();
      self.setNtMaterialien();
      self.setNtFKL();
      self.setVgebohrt();
    },

    setContentTraeger: function setContentTraeger() {
      var self = this;
      var indices = [true, false];
      self.set('contentTraeger', self.getSelectFieldContent('contentTraeger', indices));
    },

    setHtMaterialien: function setHtMaterialien() {
      var self = this;
      var indices = [0, 1, 13];
      self.set('htmaterial', self.getSelectFieldContent('materialienDB', indices));
    },

    setHtFKL: function setHtFKL(indices) {
      var self = this;
      var indices = [];

      if (this.get('htholz') === 1) {
        indices = [20, 21, 24, 22];
        self.set('htfklarray', self.getSelectFieldContent('fkl_bsh', indices));
        self.set('htfkl', 20);
      } else if (self.get('htholz') === 13) {
        indices = [30, 31, 34, 32];
        self.set('htfklarray', self.getSelectFieldContent('fkl_bsh_kombi', indices));
        self.set('htfkl', 30);
      } else {
        indices = [0, 1, 2, 3, 4, 5, 6, 7];
        self.set('htfklarray', self.getSelectFieldContent('fkl_vh', indices));
        self.set('htfkl', 5);
      }
    },

    setNtMaterialien: function setNtMaterialien() {
      var self = this;
      var indices = [0, 1, 13];
      self.set('ntmaterial', self.getSelectFieldContent('materialienDB', indices));
    },

    setNtFKL: function setNtFKL(indices) {
      var self = this;
      var indices = [];

      if (this.get('ntholz') === 1) {
        indices = [20, 21, 24, 22];
        self.set('ntfklarray', self.getSelectFieldContent('fkl_bsh', indices));
        self.set('ntfkl', 20);
      } else if (self.get('ntholz') === 13) {
        indices = [30, 31, 34, 32];
        self.set('ntfklarray', self.getSelectFieldContent('fkl_bsh_kombi', indices));
        self.set('ntfkl', 30);
      } else {
        indices = [0, 1, 2, 3, 4, 5, 6, 7];
        self.set('ntfklarray', self.getSelectFieldContent('fkl_vh', indices));
        self.set('ntfkl', 5);
      }
    },

    setVgebohrt: function setVgebohrt() {
      var self = this;
      var indices = [false, true];
      self.set('vgebohrt', self.getSelectFieldContent('vgebohrt', indices));
    },

    getSelectFieldContent: function getSelectFieldContent(db, indexes) {
      var self = this;
      var tmp = [];
      var dbContent = self.getDBContent(db);
      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].id === indexes[i]) {
            tmp.push({ id: dbContent[k].id, name: this.get('i18n').t(dbContent[k].name) });
          }
        }
      }

      return tmp;
    },

    getDBContent: function getDBContent(db) {
      var dbContent = [];
      switch (db) {

        case 'contentTraeger':
          dbContent = this.get('traeger');
          break;
        case 'materialienDB':
          dbContent = materialienDB.materialien_de_m03;
          break;
        case 'fkl_vh':
          dbContent = fklDB_new.fkl_vh.fklassen;
          break;
        case 'fkl_bsh':
          dbContent = fklDB_new.fkl_bsh.fklassen;
          break;
        case 'fkl_bsh_kombi':
          dbContent = fklDB_new.fkl_bsh_kombi.fklassen;
          break;
        case 'fkl_baubuche':
          dbContent = fklDB_new.fkl_baubuche.fklassen;
          break;
        case 'fkl_baufurniersperrholz':
          dbContent = fklDB_new.fkl_baufurniersperrholz.fklassen;
          break;
        case 'fkl_osb_platten':
          dbContent = fklDB_new.fkl_osb_platten.fklassen;
          break;
        case 'fkl_kunstharzgebundene_holzspanplatten':
          dbContent = fklDB_new.fkl_kunstharzgebundene_holzspanplatten.fklassen;
          break;
        case 'fkl_zementgebundene_holzspanplatten':
          dbContent = fklDB_new.fkl_zementgebundene_holzspanplatten.fklassen;
          break;
        case 'fkl_vollholz_laubholz':
          dbContent = fklDB_new.fkl_vollholz_laubholz.fklassen;
          break;
        case 'fkl_stahl':
          dbContent = fklDB_new.fkl_stahl.fklassen;
          break;
        case 'vgebohrt':
          dbContent = vorgebohrt.auswahlfeld;
          break;
      }
      return dbContent;
    },

    getValueFromSelectField: function getValueFromSelectField(contentArray, index) {
      var self = this;

      var tmp = "";
      var len = contentArray.length;

      for (var i = 0; i < len; i++) {
        if (contentArray[i].id === index) {
          tmp = contentArray[i].name;
        }
      }
      return tmp;
    },

    watchNumericalInputs: (function () {

      if (!this.openFromFile) {

        if (this.get('htbreite').indexOf(",") !== -1) {
          this.set('htbreite', this.get('htbreite').replace(",", "."));
        }

        if (this.get('hthoehe').indexOf(",") !== -1) {
          this.set('hthoehe', this.get('hthoehe').replace(",", "."));
        }

        if (this.get('ntbreite').indexOf(",") !== -1) {
          this.set('ntbreite', this.get('ntbreite').replace(",", "."));
        }

        if (this.get('nthoehe').indexOf(",") !== -1) {
          this.set('nthoehe', this.get('nthoehe').replace(",", "."));
        }
      }
    }).observes('htbreite', 'hthoehe', 'ntbreite', 'nthoehe'),

    changeHtSt: (function () {
      this.controllerFor('application').get('model').m06default.objectAt(0).set('istHt', this.get('httraeger'));
      this.controllerFor('application').zentriertObjekt();

      var ag = this.controllerFor('anschlussgeometrie');

      if (this.get('httraeger')) {
        ag.set('winkeldisabled', false);
      } else {
        ag.set('winkeldisabled', true);
      }

      this.set('openFromFile', true);
      this.send('validation');
      this.set('openFromFile', false);
    }).observes('httraeger'),

    istHt: (function () {
      return this.controllerFor('application').get('model').m06default.objectAt(0).get('istHt');
    }).property('httraeger'),

    watchHtMaterial: (function () {
      var self = this;

      self.set('te1laubholz', vorgebohrt.materialien.findBy('id', this.get('htholz')).laubholz);
      self.set('te1vorgebohrt', vorgebohrt.materialien.findBy('id', this.get('htholz')).value);

      this.setHtFKL();
    }).observes('htholz'),

    watchNtMaterial: (function () {
      var self = this;

      self.set('te2laubholz', vorgebohrt.materialien.findBy('id', this.get('ntholz')).laubholz);
      self.set('te2vorgebohrt', vorgebohrt.materialien.findBy('id', this.get('ntholz')).value);

      this.setNtFKL();
    }).observes('ntholz'),

    watchTe1Vorgebohrt: (function () {

      var self = this;

      self.resetVerbindungsmittel();

      var bauteile = self.controllerFor('application').get('model').bauteile.objectAt(0);
      bauteile.set('TimberElementHT_Predrilled', self.get('te1vorgebohrt'));
    }).observes('te1vorgebohrt'),

    watchTe2Vorgebohrt: (function () {

      var self = this;

      self.resetVerbindungsmittel();

      var bauteile = self.controllerFor('application').get('model').bauteile.objectAt(0);
      bauteile.set('TimberElementNT_Predrilled', self.get('te2vorgebohrt'));
    }).observes('te2vorgebohrt'),

    resetVerbindungsmittel: function resetVerbindungsmittel() {
      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);
      applicationdata.set('dxfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);
      downloadcenter.set('dxfErstellt', false);
    },

    setX3D: function setX3D(value, event) {

      var application = this.controllerFor('application');
      var m06 = application.get('model').m06default.objectAt(0);
      var bauteile = application.get('model').m06default.objectAt(0).get('bauteile');

      var self = this;

      application.get('model').m06default.objectAt(0).set('ergebnisGeladen', false);
      application.get('model').m06default.objectAt(0).set('aktuellerSchraubenTyp', 'standardSchraube');
      application.get('model').m06default.objectAt(0).set('schraubenlisteAktualisiert', !application.get('model').m06default.objectAt(0).get('schraubenlisteAktualisiert'));

      value = parseFloat(value).toFixed(1);

      switch (event.target.name) {
        case 'htbreite':
          if (this.errors.htbreite.length === 0) {
            bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).set('x', value);
            application.get('model').m06default.objectAt(0).set('htBreiteEingetragen', true);

            if (this.errors.hthoehe.length !== 0) {
              if (value * 1.5 > 10) {
                bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).set('y', value * 1.5);
              } else {
                bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).set('y', 10);
              }
            }

            self.setSchraubenlaenge();
          } else {
            application.get('model').m06default.objectAt(0).set('htBreiteEingetragen', false);
          }
          break;
        case 'hthoehe':
          if (this.errors.hthoehe.length === 0) {
            bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).set('y', value);
            application.get('model').m06default.objectAt(0).set('htHoeheEingetragen', true);

            if (this.errors.htbreite.length !== 0) {
              bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).set('x', value * 0.75);
            }

            self.setZPosSchraube();
            self.setSchraubenlaenge();
          } else {
            application.get('model').m06default.objectAt(0).set('htHoeheEingetragen', false);
          }
          break;
        case 'ntbreite':
          if (this.errors.ntbreite.length === 0) {
            bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).set('z', value);
            if (application.get('model').m06default.objectAt(0).get('istHt')) {
              bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).set('z', value * 4);
            }
            application.get('model').m06default.objectAt(0).set('ntBreiteEingetragen', true);

            if (this.errors.nthoehe.length !== 0) {
              bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).set('y', value * 1.5);
            }

            self.setZPosSchraube();
            self.setSchraubenlaenge();
          } else {
            application.get('model').m06default.objectAt(0).set('ntBreiteEingetragen', false);
          }
          break;
        case 'nthoehe':
          if (this.errors.nthoehe.length === 0) {
            bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).set('y', value);
            application.get('model').m06default.objectAt(0).set('ntHoeheEingetragen', true);

            if (!application.get('model').m06default.objectAt(0).get('istHt')) {
              bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).set('z', value * 2);
            }

            self.setSchraubenlaenge();

            if (this.errors.ntbreite.length !== 0) {
              bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).set('z', value * 0.75);
            }
          } else {
            application.get('model').m06default.objectAt(0).set('ntHoeheEingetragen', false);
          }
          break;
      }

      this.controllerFor('application').get('model').m06default.objectAt(0).set('transformHelper', !application.get('model').m06default.objectAt(0).get('transformHelper'));
    },

    watchComboboxes: (function () {

      var self = this;

      // var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      // applicationdata.set('treeLoaded', false);
      // applicationdata.set('initialized', false);
      // applicationdata.set('pdfErstellt', false);
      //
      // var application = self.controllerFor('application');
      // application.set('verbindungsmittelInvalid', true);
      //
      // var downloadcenter = self.controllerFor('downloadcenter');
      // downloadcenter.set('pdfErstellt', false);

      var bauteile = this.controllerFor('application').get('model').bauteile.objectAt(0);

      bauteile.set('MatKeyTimberElementHT', this.get('htholz'));
      bauteile.set('FKLKeyTimberElementHT', this.get('htfkl'));
      bauteile.set('MatKeyTimberElementNT', this.get('ntholz'));
      bauteile.set('FKLKeyTimberElementNT', this.get('ntfkl'));
      bauteile.set('HtRiegel', this.get('httraeger'));

      self.controllerFor('lasteinwirkung').setNklassen();
      self.controllerFor('lasteinwirkung').send('validation', self.controllerFor('lasteinwirkung').get('staendigeEinwirkungen'), { target: { name: "staendigeEinwirkungen" } });
    }).observes('htholz', 'htfkl', 'ntholz', 'ntfkl', 'httraeger'),

    setSchraubenlaenge: function setSchraubenlaenge() {
      var application = this.controllerFor('application');
      var m06 = application.get('model').m06default.objectAt(0);
      var bauteile = application.get('model').m06default.objectAt(0).get('bauteile');

      var hWinkel = m06.get('hWinkel') * Math.PI / 180;
      var vWinkel = m06.get('vWinkel') * Math.PI / 180;

      var htHoehe = m06.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('y');
      var ntHoehe = m06.get('bauteile').findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y');

      var breiteHT = m06.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x');

      var values = [htHoehe * 2 / 3, ntHoehe * 2 / 3, breiteHT * 2 / 3];

      values.sort(function (a, b) {
        return a - b;
      });

      bauteile.forEach(function (item) {
        if (item.get('typ') === 'standardSchraube') {

          var schraubenLaengeAlt = bauteile.findBy('id', item.id).get('screwProperties').objectAt(0).get('length');

          var schraubenLaenge = values[0] / Math.sin(m06.get('bauteile').findBy('id', item.id).get('rotations').objectAt(0).get('z'));

          var y = -(Math.sin(bauteile.findBy('id', item.id).get('rotations').objectAt(0).get('z')) * schraubenLaenge) - Math.abs(Math.tan(vWinkel) * (Math.cos(bauteile.findBy('id', item.id).get('rotations').objectAt(0).get('z')) * schraubenLaenge / 2));

          var laengeAltRelativ = Math.cos(bauteile.findBy('id', item.id).get('rotations').objectAt(0).get('z')) * schraubenLaengeAlt / 2;
          var laengeNeuRelativ = Math.cos(bauteile.findBy('id', item.id).get('rotations').objectAt(0).get('z')) * schraubenLaenge / 2;

          var zAlt = bauteile.findBy('id', item.id).get('translations').objectAt(0).get('z');

          bauteile.findBy('id', item.id).get('translations').objectAt(0).set('x', Math.sin(hWinkel) * (Math.cos(item.get('rotations').objectAt(0).get('z')) * schraubenLaenge / 2));
          bauteile.findBy('id', item.id).get('translations').objectAt(0).set('y', y);

          // if(!application.get('model').m06default.objectAt(0).get('schraubenGekreuzt') && (  bauteile.findBy('id', item.id).get('translations').objectAt(0).get('x') > 0)){
          //   bauteile.findBy('id', item.id).get('translations').objectAt(0).set('z', Math.abs((-zAlt - Math.cos(hWinkel) * laengeAltRelativ) + Math.cos(hWinkel) * laengeNeuRelativ));
          // }else{
          bauteile.findBy('id', item.id).get('translations').objectAt(0).set('z', zAlt - zAlt / Math.abs(zAlt) * Math.cos(hWinkel) * laengeAltRelativ - zAlt / Math.abs(zAlt) * Math.cos(hWinkel) * laengeNeuRelativ);
          // }
          bauteile.findBy('id', item.id).get('screwProperties').objectAt(0).set('length', Math.abs(schraubenLaenge));
          bauteile.findBy('id', item.id).get('screwProperties').objectAt(0).set('threadlengthtip', Math.abs(schraubenLaenge));
        }
      });
    },

    setZPosSchraube: function setZPosSchraube() {

      var application = this.controllerFor('application');
      var m06 = application.get('model').m06default.objectAt(0);
      var bauteile = application.get('model').m06default.objectAt(0).get('bauteile');

      var ntBreite = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z');
      var htBreite = bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('y');

      application.get('model').m06default.objectAt(0).get('bauteile').forEach(function (item) {
        if (item.id.substring(0, 8) === 'schraube') {

          var hWinkel = application.get('model').m06default.objectAt(0).get('hWinkel') * Math.PI / 180;
          var itemTranslations = item.get('translations').objectAt(0);
          var itemLaenge = Math.cos(item.get('rotations').objectAt(0).get('z')) * item.get('screwProperties').objectAt(0).get('length') / 2;

          if (m06.get('istHt') || Number(htBreite) > ntBreite) {
            if (itemTranslations.get('x') < 0) {
              itemTranslations.set('z', Math.cos(hWinkel) * itemLaenge - ntBreite / 5.5);
            } else {
              if (m06.get('schraubenGekreuzt')) {
                itemTranslations.set('z', Math.cos(hWinkel) * itemLaenge + ntBreite / 5.5);
              } else {
                itemTranslations.set('z', Math.abs(Math.cos(hWinkel) * itemLaenge) + ntBreite / 5.5);
              }
            }
          } else {
            if (itemTranslations.get('x') < 0) {
              itemTranslations.set('z', Math.cos(hWinkel) * itemLaenge - htBreite / 5.5);
            } else {
              if (m06.get('schraubenGekreuzt')) {
                itemTranslations.set('z', Math.cos(hWinkel) * itemLaenge + htBreite / 5.5);
              } else {
                itemTranslations.set('z', Math.abs(Math.cos(hWinkel) * itemLaenge) + htBreite / 5.5);
              }
            }
          }
        }
      });
    },

    actions: {

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('bauteileInvalid', false);

            // var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            // applicationdata.set('treeLoaded', false);
            // applicationdata.set('initialized', false);
            // applicationdata.set('pdfErstellt', false);
            //
            // var downloadcenter = self.controllerFor('downloadcenter');
            // downloadcenter.set('pdfErstellt', false);
            //
            // application.set('verbindungsmittelInvalid', true);

            self.resetVerbindungsmittel();

            var bauteile = self.controllerFor('application').get('model').bauteile.objectAt(0);

            if (self.get('httraeger') === false) {
              bauteile.set('TimberElementHT_b', parseFloat(self.get('hthoehe')).toFixed(1));
              bauteile.set('TimberElementHT_h', parseFloat(self.get('htbreite')).toFixed(1));
            } else {
              bauteile.set('TimberElementHT_b', parseFloat(self.get('htbreite')).toFixed(1));
              bauteile.set('TimberElementHT_h', parseFloat(self.get('hthoehe')).toFixed(1));
            }

            bauteile.set('TimberElementNT_b', parseFloat(self.get('ntbreite')).toFixed(1));
            bauteile.set('TimberElementNT_h', parseFloat(self.get('nthoehe')).toFixed(1));
          } else {
            self.set('displayErrors', true);
            application.set('bauteileInvalid', true);
          }
        });
      },

      htbreiteIsSelected: function htbreiteIsSelected() {
        var htbreiteaktiv = this.controllerFor('application').get('model').m06default.objectAt(0).get('htbreiteAktiv');
        this.controllerFor('application').get('model').m06default.objectAt(0).set('htbreiteAktiv', !htbreiteaktiv);

        if (this.get('htbreite') !== "") {
          var htbreitework = parseFloat(this.get('htbreite').replace(",", "."));
          var htbreiterounded = htbreitework.toFixed(1);
          this.set('htbreite', htbreiterounded);
          this.send('validation', htbreiterounded, { target: { name: "htbreite" } });
        }

        if (htbreiteaktiv === false) {
          document.getElementsByName('htbreite')[0].setSelectionRange(0, this.get('htbreite').length);
        }
      },

      hthoeheIsSelected: function hthoeheIsSelected() {
        var hthoeheaktiv = this.controllerFor('application').get('model').m06default.objectAt(0).get('hthoeheAktiv');
        this.controllerFor('application').get('model').m06default.objectAt(0).set('hthoeheAktiv', !hthoeheaktiv);

        if (this.get('hthoehe') !== "") {
          var hthoehework = parseFloat(this.get('hthoehe').replace(",", "."));
          var hthoeherounded = hthoehework.toFixed(1);
          this.set('hthoehe', hthoeherounded);
          this.send('validation', hthoeherounded, { target: { name: "hthoehe" } });
        }

        if (hthoeheaktiv == false) {
          document.getElementsByName('hthoehe')[0].setSelectionRange(0, this.get('hthoehe').length);
        }
      },

      ntbreiteIsSelected: function ntbreiteIsSelected() {
        var ntbreiteaktiv = this.controllerFor('application').get('model').m06default.objectAt(0).get('ntbreiteAktiv');
        this.controllerFor('application').get('model').m06default.objectAt(0).set('ntbreiteAktiv', !ntbreiteaktiv);

        if (this.get('ntbreite') !== "") {
          var ntbreitework = parseFloat(this.get('ntbreite').replace(",", "."));
          var ntbreiterounded = ntbreitework.toFixed(1);
          this.set('ntbreite', ntbreiterounded);
          this.send('validation', ntbreiterounded, { target: { name: "ntbreite" } });
        }

        if (ntbreiteaktiv === false) {
          document.getElementsByName('ntbreite')[0].setSelectionRange(0, this.get('ntbreite').length);
        }
      },

      nthoeheIsSelected: function nthoeheIsSelected() {
        var nthoeheaktiv = this.controllerFor('application').get('model').m06default.objectAt(0).get('nthoeheAktiv');
        this.controllerFor('application').get('model').m06default.objectAt(0).set('nthoeheAktiv', !nthoeheaktiv);

        if (this.get('nthoehe') !== "") {
          var nthoehework = parseFloat(this.get('nthoehe').replace(",", "."));
          var nthoeherounded = nthoehework.toFixed(1);
          this.set('nthoehe', nthoeherounded);
          this.send('validation', nthoeherounded, { target: { name: "nthoehe" } });
        }

        if (nthoeheaktiv === false) {
          document.getElementsByName('nthoehe')[0].setSelectionRange(0, this.get('nthoehe').length);
        }
      },

      removeClass: function removeClass(element) {
        var self = this;
        self.set(element, false);
      }

    }, // End Actions

    // hilfeTexte obeserver

    observes_htholzHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "htholz", self.get('htholz').toString());
      self.controllerFor("supercontroller").setHilfetexte("bauteile", hilfetextArray);
    }).observes('htholz', 'initTrigger'),

    observes_htfklHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "htfkl", "0");
      self.controllerFor("supercontroller").setHilfetexte("bauteile", hilfetextArray);
    }).observes('htfkl', 'initTrigger'),

    observes_te1vorgebohrtHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "te1vorgebohrt", "0");

      if (Number(self.get("htholz")) !== 30) {
        self.controllerFor("supercontroller").setHilfetexte("bauteile", hilfetextArray);
      }
    }).observes('te1vorgebohrt', 'initTrigger'),

    observes_ntholzHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "ntholz", self.get('ntholz').toString());
      self.controllerFor("supercontroller").setHilfetexte("bauteile", hilfetextArray);
    }).observes('ntholz', 'initTrigger'),

    observes_ntfklHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "ntfkl", "0");
      self.controllerFor("supercontroller").setHilfetexte("bauteile", hilfetextArray);
    }).observes('ntfkl', 'initTrigger'),

    observes_te2vorgebohrtHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "te2vorgebohrt", "0");

      if (Number(self.get("ntholz")) !== 30) {
        self.controllerFor("supercontroller").setHilfetexte("bauteile", hilfetextArray);
      }
    }).observes('te2vorgebohrt', 'initTrigger'),

    // hilfeTexte display properties

    display_te1vorgebohrtHilfetext: (function () {
      var self = this;

      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('te1vorgebohrtHilfetext'));
    }).property('te1vorgebohrtHilfetext'),

    display_te2vorgebohrtHilfetext: (function () {
      var self = this;

      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('te2vorgebohrtHilfetext'));
    }).property('te2vorgebohrtHilfetext')

  });

});