define('m20/tests/ember-cli-i18n-test', ['ember', 'm20/config/environment'], function (Ember, config) {

  'use strict';

  /* globals requirejs, require */

  if (window.QUnit) {
    var keys = Ember['default'].keys;

    var locales, defaultLocale;
    module('ember-cli-i18n', {
      setup: function setup() {
        var localRegExp = new RegExp(config['default'].modulePrefix + '/locales/(.+)');
        var match, moduleName;

        locales = {};

        for (moduleName in requirejs.entries) {
          if (match = moduleName.match(localRegExp)) {
            locales[match[1]] = require(moduleName)['default'];
          }
        }

        defaultLocale = locales[config['default'].APP.defaultLocale];
      }
    });

    test('locales all contain the same keys', function () {
      var knownLocales = keys(locales);
      if (knownLocales.length === 1) {
        expect(0);
        return;
      }

      for (var i = 0, l = knownLocales.length; i < l; i++) {
        var currentLocale = locales[knownLocales[i]];

        if (currentLocale === defaultLocale) {
          continue;
        }

        for (var translationKey in defaultLocale) {
          ok(currentLocale[translationKey], '`' + translationKey + '` should exist in the `' + knownLocales[i] + '` locale.');
        }
      }
    });
  }

});