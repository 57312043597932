define('m20/components/schraub-e', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Schraube = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'schraub-e',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['schraubenKlasse'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    center: (function () {
      var values = '0 0 0';
      var name = this.get('name');

      return values;
    }).property(''),

    translation: (function () {
      var values = '0 0 0';

      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = this.get('model').objectAt(0).get('bauteile');

      var positionierungsFaktor = 1;
      var drehwinckelZAchse = this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('rotations').objectAt(0).get('z');

      var zVerschiebung = 0;

      var x = 0,
          y = 0,
          z = 0;

      if (x3d.get('ergebnisGeladen')) {

        x = bauteile.findBy('id', name).get('translations').objectAt(0).get('x') / 10;
        y = bauteile.findBy('id', name).get('translations').objectAt(0).get('y') / 10;
        z = bauteile.findBy('id', name).get('translations').objectAt(0).get('z') / 10;
      } else {
        if (!this.get('model').objectAt(0).get('schraubenGekreuzt') && drehwinckelZAchse > 0) {
          positionierungsFaktor = -1;
          var itemLaenge = Math.cos(bauteile.findBy('id', 'schraubeStandard2').get('rotations').objectAt(0).get('z')) * bauteile.findBy('id', 'schraubeStandard2').get('screwProperties').objectAt(0).get('length') / 2;
          zVerschiebung = Math.cos(Number(x3d.get('hWinkel')) * Math.PI / 180) * itemLaenge - bauteile.findBy('id', 'schraubeStandard2').get('translations').objectAt(0).get('z');
        }

        x = bauteile.findBy('id', name).get('translations').objectAt(0).get('x') / 10 * positionierungsFaktor;
        y = bauteile.findBy('id', name).get('translations').objectAt(0).get('y') / 10;
        z = bauteile.findBy('id', name).get('translations').objectAt(0).get('z') / 10 * positionierungsFaktor + 0.2 * zVerschiebung;
      }

      values = x + ' ' + y + ' ' + z;

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt'),

    // Rotation in der XY-Ebene, um die z-Achse
    rotation: (function () {
      var name = this.get('name');

      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);

      var drehwinckelZAchse = item.get('rotations').objectAt(0).get('z');
      var drehwinckelYAchse = item.get('rotations').objectAt(0).get('y');
      var values = '0 0 0 0';
      var positionierungsFaktor = 1;

      if (!this.get('model').objectAt(0).get('schraubenGekreuzt') && drehwinckelZAchse > 0) {
        positionierungsFaktor = -1;
      }

      if (x3d.get('ergebnisGeladen')) {

        // if (item.get('translations').objectAt(0).get('x') < 0){
        //   drehwinckelZAchse = -Math.abs(drehwinckelZAchse);
        // }else{
        //   drehwinckelZAchse = drehwinckelZAchse - 1.5708;
        // }

        var xWertRotationsachse = Math.cos(drehwinckelYAchse);
        var zWertRotationsachse = -Math.sin(drehwinckelYAchse);

        values = xWertRotationsachse + ' 0 ' + zWertRotationsachse + ' ' + drehwinckelZAchse;
      } else {
        var xWertRotationsachse = Math.sin(drehwinckelYAchse);
        var zWertRotationsachse = Math.cos(drehwinckelYAchse);

        values = xWertRotationsachse + ' 0 ' + zWertRotationsachse + ' ' + drehwinckelZAchse * positionierungsFaktor;
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.schraubenGekreuzt')
  });

  exports['default'] = Schraube;

});