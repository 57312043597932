define('m20/components/transfor-m', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'transfor-m',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    // center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",
    test: 1337,

    center: (function () {
      var values = '0 0 0';

      return values;
    }).property(''),

    translation: (function () {
      var name = this.get('name');
      var m06 = this.get('model');
      var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);
      var values = '0 0 0';
      var y;
      var xVerschiebung, yVerschiebung;

      switch (name) {
        case 'hauptTraeger':
          xVerschiebung = item.get('boxsizes').objectAt(0).get('x') / 10;
          yVerschiebung = -item.get('boxsizes').objectAt(0).get('y') / 10;
          var zVerschiebung = 0;
          if (m06.objectAt(0).get('istHt') === false) {
            xVerschiebung = item.get('boxsizes').objectAt(0).get('y') / 10;
            yVerschiebung = -item.get('boxsizes').objectAt(0).get('z') / 10;
          }
          values = xVerschiebung / 2 + " " + yVerschiebung + " " + zVerschiebung;
          break;
        case 'nebenTraeger':
          var vwinkel = 0 + Number(m06.objectAt(0).get('vWinkel')) * Math.PI / 180;
          var hwinkel = 0 + Number(m06.objectAt(0).get('hWinkel')) * Math.PI / 180;
          var z = item.get('boxsizes').objectAt(0).get('z') / 10;
          y = item.get('boxsizes').objectAt(0).get('y') / 10;
          var deltaZ = y * Math.tan(vwinkel) * Math.tan(90 * Math.PI / 180 - hwinkel);
          var zVerschiebung = z * Math.cos(hwinkel) / 2;
          yVerschiebung = y / Math.cos(vwinkel);
          values = 0 + " " + -yVerschiebung + " " + deltaZ;
          break;
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt'),

    rotation: (function () {
      var name = this.get('name');
      var m06 = this.get('model');
      var bauteile = this.get('model').objectAt(0).get('bauteile');
      var values;

      if (name.substring(0, 8) === 'schraube') {

        values = '0 0 0 0';
      } else {
        values = bauteile.findBy('id', name).get('rotations').objectAt(0).get('x') + ' ' + bauteile.findBy('id', name).get('rotations').objectAt(0).get('y') + ' ' + bauteile.findBy('id', name).get('rotations').objectAt(0).get('z') + ' ' + bauteile.findBy('id', name).get('rotations').objectAt(0).get('winkel');
      }
      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.schraubenGekreuzt'),

    getOutline: (function () {
      var name = this.get('name');
      var bauteile = this.get('model').objectAt(0).get('bauteile');
      var istVolumen = false;

      if (bauteile.findBy('id', name).get('typ') === 'box') {
        istVolumen = true;
      }
      return istVolumen;
    }).property(''),

    abstaendeAnzeigen: (function () {
      var x3d = this.get('model').objectAt(0);
      return x3d.get('abstaendeAnzeigen');
    }).property('model.firstObject.abstaendeAnzeigen'),

    ansichtLinks: (function () {
      var value = false;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') === true) {
        value = true;
      }
      return value;
    }).property('model.firstObject.lastViewpoint'),

    ansichtFront: (function () {
      var value = false;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') === true && name !== 'hauptTraeger') {
        value = true;
      }

      return value;
    }).property('model.firstObject.lastViewpoint'),

    ansichtOben: (function () {
      var value = false;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true && x3d.get('istHt') === true || bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true && x3d.get('istHt') === false && name === 'nebenTraeger' || bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') === true && name === 'hauptTraeger' && x3d.get('istHt') === false) {
        value = true;
      }

      return value;
    }).property('model.firstObject.lastViewpoint', 'model.firstObject.transformHelper')

  });

  exports['default'] = Transform;

});