define('m20/components/indexedfacese-t', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'indexedFaceSet',
    layoutName: 'indexedfacese-t',
    attributeBindings: ['coordIndex', 'colorindex', 'colorPerVertex', 'ccw'],
    classNames: ['testClassNameXXX'],
    colorPerVertex: "false",
    bboxsize: "-1,-1,-1",
    ccw: "true",

    objektSkalierung: 1,

    koordinaten: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var m06 = this.get('model');
      var bauteile = m06.objectAt(0).get('bauteile');

      var item = m06.objectAt(0).get('bauteile').findBy('id', name);

      var x = item.get('boxsizes').objectAt(0).get('x') / 10;
      var y = item.get('boxsizes').objectAt(0).get('y') / 10;
      var z = item.get('boxsizes').objectAt(0).get('z') / 10;

      var hwinkel = Number(m06.objectAt(0).get('hWinkel')) * Math.PI / 180;
      var vwinkel = Number(m06.objectAt(0).get('vWinkel')) * Math.PI / 180;

      // var yPlus = (x*Math.tan(vwinkel));
      // var zPlus = x/Math.tan(hwinkel);

      var deltaZ = y * Math.tan(vwinkel) * Math.tan(90 * Math.PI / 180 - hwinkel);

      var yNeu = y / Math.cos(vwinkel);
      var zNeu = z / Math.sin(hwinkel);

      //untere Ebene
      var p0 = (-x * Math.sin(hwinkel) - z / 2 * Math.cos(hwinkel)) * Math.cos(vwinkel) + ' ' + (0 - (x * Math.sin(hwinkel) + z / 2 * Math.cos(hwinkel)) * Math.sin(vwinkel)) + ' ' + (-zNeu + x * Math.cos(hwinkel) + (zNeu - z / 2 * Math.sin(hwinkel)));
      var p1 = (-x * Math.sin(hwinkel) + z / 2 * Math.cos(hwinkel)) * Math.cos(vwinkel) + ' ' + (0 - (x * Math.sin(hwinkel) - z / 2 * Math.cos(hwinkel)) * Math.sin(vwinkel)) + ' ' + (-zNeu + x * Math.cos(hwinkel) + (zNeu + z / 2 * Math.sin(hwinkel)));
      var p2 = 0 + ' ' + 0 + ' ' + zNeu / 2;
      var p3 = 0 + ' ' + 0 + ' ' + -zNeu / 2;

      // obere Ebene
      var p4 = (-x * Math.sin(hwinkel) - z / 2 * Math.cos(hwinkel)) * Math.cos(vwinkel) - y * Math.sin(vwinkel) + ' ' + (yNeu - (x * Math.sin(hwinkel) + z / 2 * Math.cos(hwinkel)) * Math.sin(vwinkel) - (yNeu - y * Math.cos(vwinkel))) + ' ' + (-zNeu / 2 + x * Math.cos(hwinkel) + (zNeu / 2 - z / 2 * Math.sin(hwinkel)));
      var p5 = (-x * Math.sin(hwinkel) + z / 2 * Math.cos(hwinkel)) * Math.cos(vwinkel) - y * Math.sin(vwinkel) + ' ' + (yNeu - (x * Math.sin(hwinkel) - z / 2 * Math.cos(hwinkel)) * Math.sin(vwinkel) - (yNeu - y * Math.cos(vwinkel))) + ' ' + (-zNeu / 2 + x * Math.cos(hwinkel) + (zNeu / 2 + z / 2 * Math.sin(hwinkel)));
      var p6 = 0 + ' ' + yNeu + ' ' + (zNeu / 2 - deltaZ);
      var p7 = 0 + ' ' + yNeu + ' ' + (-zNeu / 2 - deltaZ);

      var cords = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7;

      return cords;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.istHt', 'model.firstObject.skalierungsfaktor', 'model.firstObject.schraubenGekreuzt'),

    coordIndex: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var m06 = this.get('model');
      var bauteile = m06.objectAt(0).get('bauteile');
      var values = '';

      var item = m06.objectAt(0).get('bauteile').findBy('id', name);

      var values = "0 3 2 1 0 -1 1 2 6 5 1 -1 4 5 6 7 4 -1 0 4 7 3 0 -1 0 1 5 4 0 -1 2 3 7 6 2 -1";

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt'),

    colorindex: (function () {

      var values = "0 0 0 0 0 0 0";

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt'),

    farbe: (function () {

      var name = this.get('name');
      var typ = this.get('typ');
      var m06 = this.get('model');
      var bauteile = m06.objectAt(0).get('bauteile');

      var item = m06.objectAt(0).get('bauteile').findBy('id', name);

      var values = item.get('diffusecolors').objectAt(0).get('rot') + " " + item.get('diffusecolors').objectAt(0).get('gruen') + " " + item.get('diffusecolors').objectAt(0).get('blau');

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt')

  });

  exports['default'] = Transform;

});