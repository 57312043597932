define('m20/components/indexedlinese-t', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'indexedlinese-t',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    translation: (function () {
      var name = this.get('name');
      var m06 = this.get('model').objectAt(0);
      var bauteile = m06.get('bauteile');
      var bezug = this.get('typ');

      var x = 0;
      var y = 0;
      var z = 0;

      var cords;

      var item = m06.get('bauteile').findBy('id', name);

      if (name === 'lastenPfeil') {
        x = item.get('translations').objectAt(0).get('x');
        y = item.get('translations').objectAt(0).get('y');
        z = item.get('translations').objectAt(0).get('z');
        cords = x + ' ' + y + ' ' + z;
      }

      return cords;
    }).property('model.firstObject.transformHelper'),

    rotation: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var m06 = this.get('model').objectAt(0);
      var bauteile = m06.get('bauteile');
      var values;

      var item = m06.get('bauteile').findBy('id', name);

      if (name === 'lastenPfeil') {
        values = item.get('rotations').objectAt(0).get('x') + " " + item.get('rotations').objectAt(0).get('y') + " " + item.get('rotations').objectAt(0).get('z') + " " + item.get('rotations').objectAt(0).get('winkel');
      }

      if (typ === 'hWinkel') {
        // values = '1 0 0 -1.5707';
        values = '0 0 0 0';
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt'),

    koordinaten: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var m06 = this.get('model').objectAt(0);
      var bauteile = m06.get('bauteile');
      var item = bauteile.findBy('id', name);

      var hwinkel = Number(m06.get('hWinkel'));
      var vwinkel = Number(m06.get('vWinkel'));

      var x = 0;
      var y = 0;
      var z = 0;

      // var skalierungsfaktor = m06.get('skalierungsfaktor');
      var skalierungsfaktor = 1;

      var p0, p1, p2, p3, p4, p5, p6, p7, p8, p9;

      var b05 = 0.05 * skalierungsfaktor;
      var b1 = 0.1 * skalierungsfaktor;
      var b5 = 0.5 * skalierungsfaktor;
      var b45 = b5 - b05;
      var b55 = b5 + b05;
      var b6 = 0.6 * skalierungsfaktor;

      var w90Grad = 90 * Math.PI / 180;

      var hoeheMasskette = 0.5;
      var abstandVomBauteil = 0.15;
      var korrektur = 0.05;

      var cords;

      var item = m06.get('bauteile').findBy('id', name);

      if (name === "nebenTraeger") {

        x = item.get('boxsizes').objectAt(0).get('x') / 10;
        y = item.get('boxsizes').objectAt(0).get('y') / 10;
        z = item.get('boxsizes').objectAt(0).get('z') / 10;

        hwinkel = hwinkel * Math.PI / 180;
        vwinkel = vwinkel * Math.PI / 180;

        // var yPlus = (x*Math.tan(vwinkel));
        // var zPlus = x/Math.tan(hwinkel);

        var deltaZ = y * Math.tan(vwinkel) * Math.tan(90 * Math.PI / 180 - hwinkel);

        var yNeu = y / Math.cos(vwinkel);
        var zNeu = z / Math.sin(hwinkel);

        if (typ === 'kontur') {

          //untere Ebene
          var kp0 = (-x * Math.sin(hwinkel) - z / 2 * Math.cos(hwinkel)) * Math.cos(vwinkel) + ' ' + (0 - (x * Math.sin(hwinkel) + z / 2 * Math.cos(hwinkel)) * Math.sin(vwinkel)) + ' ' + (-zNeu + x * Math.cos(hwinkel) + (zNeu - z / 2 * Math.sin(hwinkel)));
          var kp1 = (-x * Math.sin(hwinkel) + z / 2 * Math.cos(hwinkel)) * Math.cos(vwinkel) + ' ' + (0 - (x * Math.sin(hwinkel) - z / 2 * Math.cos(hwinkel)) * Math.sin(vwinkel)) + ' ' + (-zNeu + x * Math.cos(hwinkel) + (zNeu + z / 2 * Math.sin(hwinkel)));
          var kp2 = 0 + ' ' + 0 + ' ' + zNeu / 2;
          var kp3 = 0 + ' ' + 0 + ' ' + -zNeu / 2;

          // obere Ebene
          var kp4 = (-x * Math.sin(hwinkel) - z / 2 * Math.cos(hwinkel)) * Math.cos(vwinkel) - y * Math.sin(vwinkel) + ' ' + (yNeu - (x * Math.sin(hwinkel) + z / 2 * Math.cos(hwinkel)) * Math.sin(vwinkel) - (yNeu - y * Math.cos(vwinkel))) + ' ' + (-zNeu / 2 + x * Math.cos(hwinkel) + (zNeu / 2 - z / 2 * Math.sin(hwinkel)));
          var kp5 = (-x * Math.sin(hwinkel) + z / 2 * Math.cos(hwinkel)) * Math.cos(vwinkel) - y * Math.sin(vwinkel) + ' ' + (yNeu - (x * Math.sin(hwinkel) - z / 2 * Math.cos(hwinkel)) * Math.sin(vwinkel) - (yNeu - y * Math.cos(vwinkel))) + ' ' + (-zNeu / 2 + x * Math.cos(hwinkel) + (zNeu / 2 + z / 2 * Math.sin(hwinkel)));
          var kp6 = 0 + ' ' + yNeu + ' ' + (zNeu / 2 - deltaZ);
          var kp7 = 0 + ' ' + yNeu + ' ' + (-zNeu / 2 - deltaZ);

          cords = kp0 + ', ' + kp1 + ', ' + kp2 + ', ' + kp3 + ', ' + kp4 + ', ' + kp5 + ', ' + kp6 + ', ' + kp7;
        }

        var p1 = new Object();
        var p2 = new Object();
        var drehachse = new Object();
        var lageachse = new Object();

        var drehwinkel = 90 * Math.PI / 180;
        var lagewinkel = 90 * Math.PI / 180;

        var c0, c1, c2, c3, c4, c5, c6, c7, c8, c9;

        if (typ === 'breite') {

          x = Number(x);
          y = Number(y);
          z = Number(z);

          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {

            c0 = this.getCords(x, y, -z / 2, vwinkel, hwinkel);
            c1 = this.getCords(x, y, z / 2, vwinkel, hwinkel);
            c2 = this.getCords(x + b6, y, -z / 2, vwinkel, hwinkel);
            c3 = this.getCords(x + b6, y, z / 2, vwinkel, hwinkel);
            c4 = this.getCords(x + b5, y, z / 2 + b1, vwinkel, hwinkel);
            c5 = this.getCords(x + b5, y, -z / 2 - b1, vwinkel, hwinkel);
            c6 = this.getCords(x + b45, y, z / 2 + b05, vwinkel, hwinkel);
            c7 = this.getCords(x + b55, y, z / 2 - b05, vwinkel, hwinkel);
            c8 = this.getCords(x + b45, y, -z / 2 + b05, vwinkel, hwinkel);
            c9 = this.getCords(x + b55, y, -z / 2 - b05, vwinkel, hwinkel);
          } else {

            c0 = this.getCords(x, y, -z / 2, vwinkel, hwinkel);
            c1 = this.getCords(x, y, z / 2, vwinkel, hwinkel);
            c2 = this.getCords(x, y + b6, -z / 2, vwinkel, hwinkel);
            c3 = this.getCords(x, y + b6, z / 2, vwinkel, hwinkel);
            c4 = this.getCords(x, y + b5, z / 2 + b1, vwinkel, hwinkel);
            c5 = this.getCords(x, y + b5, -z / 2 - b1, vwinkel, hwinkel);
            c6 = this.getCords(x, y + b45, z / 2 + b05, vwinkel, hwinkel);
            c7 = this.getCords(x, y + b55, z / 2 - b05, vwinkel, hwinkel);
            c8 = this.getCords(x, y + b45, -z / 2 + b05, vwinkel, hwinkel);
            c9 = this.getCords(x, y + b55, -z / 2 - b05, vwinkel, hwinkel);
          }

          cords = c0 + ' ' + c1 + ' ' + c3 + ' ' + c2 + ' ' + c4 + ' ' + c5 + ' ' + c6 + ' ' + c7 + ' ' + c8 + ' ' + c9;
        }

        if (typ === 'hoehe') {

          if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {

            c0 = this.getCords(x, y, z / 2, vwinkel, hwinkel);
            c1 = this.getCords(x, 0, z / 2, vwinkel, hwinkel);
            c2 = this.getCords(x + b6, y, z / 2, vwinkel, hwinkel);
            c3 = this.getCords(x + b6, 0, z / 2, vwinkel, hwinkel);
            c4 = this.getCords(x + b5, y + b1, z / 2, vwinkel, hwinkel);
            c5 = this.getCords(x + b5, 0 - b1, z / 2, vwinkel, hwinkel);
            c6 = this.getCords(x + b45, y + b05, z / 2, vwinkel, hwinkel);
            c7 = this.getCords(x + b55, y - b05, z / 2, vwinkel, hwinkel);
            c8 = this.getCords(x + b45, 0 + b05, z / 2, vwinkel, hwinkel);
            c9 = this.getCords(x + b55, 0 - b05, z / 2, vwinkel, hwinkel);
          } else {

            c0 = this.getCords(x, y, z / 2, vwinkel, hwinkel);
            c1 = this.getCords(x, 0, z / 2, vwinkel, hwinkel);
            c2 = this.getCords(x, y, z / 2 + b6, vwinkel, hwinkel);
            c3 = this.getCords(x, 0, z / 2 + b6, vwinkel, hwinkel);
            c4 = this.getCords(x, y + b1, z / 2 + b5, vwinkel, hwinkel);
            c5 = this.getCords(x, 0 - b1, z / 2 + b5, vwinkel, hwinkel);
            c6 = this.getCords(x, y + b05, z / 2 + b45, vwinkel, hwinkel);
            c7 = this.getCords(x, y - b05, z / 2 + b55, vwinkel, hwinkel);
            c8 = this.getCords(x, 0 + b05, z / 2 + b45, vwinkel, hwinkel);
            c9 = this.getCords(x, 0 - b05, z / 2 + b55, vwinkel, hwinkel);
          }

          cords = c0 + ' ' + c1 + ' ' + c3 + ' ' + c2 + ' ' + c4 + ' ' + c5 + ' ' + c6 + ' ' + c7 + ' ' + c8 + ' ' + c9;
        }
      }

      if (name === "hauptTraeger") {

        if (m06.get('istHt')) {
          x = item.get('boxsizes').objectAt(0).get('x') / 10;
          y = item.get('boxsizes').objectAt(0).get('y') / 10;
          z = item.get('boxsizes').objectAt(0).get('z') / 10;
        } else {
          x = item.get('boxsizes').objectAt(0).get('y') / 10;
          y = item.get('boxsizes').objectAt(0).get('z') / 10;
          z = item.get('boxsizes').objectAt(0).get('x') / 10;
        }

        if (typ === 'kontur') {
          cords = this.getKonturKoordinaten(x, y, z);
        }
        if (typ === 'breite') {
          p0 = x / 2 + ' 0 ' + z / 2;
          p1 = -x / 2 + ' ' + 0 + ' ' + z / 2;

          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            p2 = -x / 2 + ' ' + 0 + ' ' + (z / 2 + b6);
            p3 = x / 2 + ' ' + 0 + ' ' + (z / 2 + b6);
            p4 = -x / 2 - b1 + ' ' + 0 + ' ' + (z / 2 + b5);
            p5 = x / 2 + b1 + ' ' + 0 + ' ' + (z / 2 + b5);
            p6 = -x / 2 - b05 + ' ' + 0 + ' ' + (z / 2 + b55);
            p7 = -x / 2 + b05 + ' ' + 0 + ' ' + (z / 2 + b45);
            p8 = x / 2 - b05 + ' ' + 0 + ' ' + (z / 2 + b55);
            p9 = x / 2 + b05 + ' ' + 0 + ' ' + (z / 2 + b45);
          } else {
            p2 = -x / 2 + ' ' + -b6 + ' ' + z / 2;
            p3 = x / 2 + ' ' + -b6 + ' ' + z / 2;
            p4 = -x / 2 - b1 + ' ' + -b5 + ' ' + z / 2;
            p5 = x / 2 + b1 + ' ' + -b5 + ' ' + z / 2;
            p6 = -x / 2 - b05 + ' ' + -b55 + ' ' + z / 2;
            p7 = -x / 2 + b05 + ' ' + -b45 + ' ' + z / 2;
            p8 = x / 2 - b05 + ' ' + -b55 + ' ' + z / 2;
            p9 = x / 2 + b05 + ' ' + -b45 + ' ' + z / 2;
          }
        }
        if (typ === 'hoehe') {
          if (m06.get('istHt')) {
            p0 = x / 2 + ' ' + y + ' ' + z / 2;
            p1 = x / 2 + ' 0 ' + z / 2;
            if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              p2 = x / 2 + ' ' + 0 + ' ' + (z / 2 + b6);
              p3 = x / 2 + ' ' + y + ' ' + (z / 2 + b6);
              p4 = x / 2 + ' ' + -b1 + ' ' + (z / 2 + b5);
              p5 = x / 2 + ' ' + (Number(y) + b1) + ' ' + (z / 2 + b5);
              p6 = x / 2 + ' ' + -b05 + ' ' + (z / 2 + b55);
              p7 = x / 2 + ' ' + b05 + ' ' + (z / 2 + b45);
              p8 = x / 2 + ' ' + (Number(y) - b05) + ' ' + (z / 2 + b55);
              p9 = x / 2 + ' ' + (Number(y) + b05) + ' ' + (z / 2 + b45);
            } else {
              p2 = x / 2 + b6 + ' ' + 0 + ' ' + z / 2;
              p3 = x / 2 + b6 + ' ' + y + ' ' + z / 2;
              p4 = x / 2 + b5 + ' ' + -b1 + ' ' + z / 2;
              p5 = x / 2 + b5 + ' ' + (Number(y) + b1) + ' ' + z / 2;
              p6 = x / 2 + b55 + ' ' + -b05 + ' ' + z / 2;
              p7 = x / 2 + b45 + ' ' + b05 + ' ' + z / 2;
              p8 = x / 2 + b55 + ' ' + (Number(y) - b05) + ' ' + z / 2;
              p9 = x / 2 + b45 + ' ' + (Number(y) + b05) + ' ' + z / 2;
            }
          } else {
            p0 = x / 2 + ' ' + ' 0 ' + ' ' + z / 2;
            p1 = x / 2 + ' 0 ' + -z / 2;
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              p2 = x / 2 + b6 + ' ' + 0 + ' ' + -z / 2;
              p3 = x / 2 + b6 + ' ' + 0 + ' ' + z / 2;
              p4 = x / 2 + b5 + ' ' + 0 + ' ' + (-z / 2 - b1);
              p5 = x / 2 + b5 + ' ' + 0 + ' ' + (z / 2 + b1);
              p6 = x / 2 + b55 + ' ' + 0 + ' ' + (-z / 2 - b05);
              p7 = x / 2 + b45 + ' ' + 0 + ' ' + (-z / 2 + b05);
              p8 = x / 2 + b55 + ' ' + 0 + ' ' + (z / 2 - b05);
              p9 = x / 2 + b45 + ' ' + 0 + ' ' + (z / 2 + b05);
            } else {
              p2 = x / 2 + ' ' + -b6 + ' ' + -z / 2;
              p3 = x / 2 + ' ' + -b6 + ' ' + z / 2;
              p4 = x / 2 + ' ' + -b5 + ' ' + (-z / 2 - b1);
              p5 = x / 2 + ' ' + -b5 + ' ' + (z / 2 + b1);
              p6 = x / 2 + ' ' + -b55 + ' ' + (-z / 2 - b05);
              p7 = x / 2 + ' ' + -b45 + ' ' + (-z / 2 + b05);
              p8 = x / 2 + ' ' + -b55 + ' ' + (z / 2 - b05);
              p9 = x / 2 + ' ' + -b45 + ' ' + (z / 2 + b05);
            }
          }
        }
      }

      if ((name.substring(0, 8) === 'schraube' || item.get('typ') === "verbindungsmittel") && m06.get('ergebnisGeladen')) {

        var htX = 0,
            htY = 0,
            htZ = 0;

        if (m06.get('istHt') === true) {
          htX = m06.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10;
          htY = m06.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('y') / 10;
          htZ = m06.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('z') / 10;
        } else {
          htX = m06.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('y') / 10;
          htY = m06.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('z') / 10;
          htZ = m06.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10;
        }

        var xKopfPos = this.getKopfPos(name, 'x');
        var yKopfPos = this.getKopfPos(name, 'y');
        var zKopfPos = this.getKopfPos(name, 'z');

        var xBezugspunkt = 0;
        var yBezugspunkt = 0;
        var zBezugspunkt = 0;

        zBezugspunkt = htZ / 2 + abstandVomBauteil;

        var p1 = new Object();
        var p2 = new Object();

        var drehachsen = new Object();
        var drehwinkel = new Object();

        if (typ === 'xPos' && xKopfPos > 0) {
          p1.x = Number(xKopfPos);
          p1.y = Number(yBezugspunkt);
          p1.z = Number(-zBezugspunkt);

          p2.x = Number(xBezugspunkt);
          p2.y = Number(yBezugspunkt);
          p2.z = Number(-zBezugspunkt);

          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') || bauteile.findBy('id', 'viewpointDefault').get('cameraProperties').objectAt(0).get('setBind')) {
            drehwinkel.x = -w90Grad;
            drehwinkel.y = 0;
            drehwinkel.z = 0;
          } else if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
            drehwinkel.x = 0;
            drehwinkel.y = 0;
            drehwinkel.z = 0;
          }
        } else if (typ === 'xPos' && xKopfPos < 0) {
          p1.x = Number(xBezugspunkt);
          p1.y = Number(yBezugspunkt);
          p1.z = Number(-zBezugspunkt);

          p2.x = Number(xKopfPos);
          p2.y = Number(yBezugspunkt);
          p2.z = Number(-zBezugspunkt);

          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') || bauteile.findBy('id', 'viewpointDefault').get('cameraProperties').objectAt(0).get('setBind')) {
            drehwinkel.x = -w90Grad;
            drehwinkel.y = 0;
            drehwinkel.z = 0;
          } else if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
            drehwinkel.x = 0;
            drehwinkel.y = 0;
            drehwinkel.z = 0;
          }
        }

        if (typ === 'zPos') {

          //notwendig bei Ansicht von oben, da ggf. Breite der Stütze zu berücksichtigen ist
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') && m06.get('istHt') === false) {
            xBezugspunkt = htX + abstandVomBauteil + hoeheMasskette;
          } else if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            xBezugspunkt = htX + abstandVomBauteil;
            yBezugspunkt = yBezugspunkt + abstandVomBauteil + hoeheMasskette;
          } else {
            xBezugspunkt = htX + abstandVomBauteil;
          }

          var zPos = 0,
              delta = 0;

          var traegerbreite = Number(m06.get('bauteile').findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z')) / 10;

          // console.log("hWinkel vor: "+hwinkel);
          hwinkel = hwinkel * Math.PI / 180;
          // console.log("hWinkel nach: "+hwinkel);
          zBezugspunkt = traegerbreite / Math.sin(hwinkel) / 2;

          p1.x = 0;
          p1.y = 0;
          p1.z = 0;

          p2.x = 0;
          p2.y = 0;
          p2.z = 0;

          switch (this.get('variable')) {
            case 'randAbstand2':
              p1.x = Number(xBezugspunkt);
              p1.y = Number(yBezugspunkt);
              p1.z = Number(zBezugspunkt);

              p2.x = Number(xBezugspunkt);
              p2.y = Number(yBezugspunkt);
              p2.z = Number(this.getKopfPos(m06.get('zMaxSchraubeID'), 'z'));
              break;
            case 'randAbstand1':
              p1.x = Number(xBezugspunkt);
              p1.y = Number(yBezugspunkt);
              p1.z = Number(this.getKopfPos(m06.get('zMinSchraubeID'), 'z'));

              p2.x = Number(xBezugspunkt);
              p2.y = Number(yBezugspunkt);
              p2.z = Number(-zBezugspunkt);

              // console.log("z KopfPos: "+p1.z);
              // console.log("z Kante: "+p2.z);

              break;
            case 'schraubenAbstand2':
              p1.x = Number(xBezugspunkt);
              p1.y = Number(yBezugspunkt);
              p1.z = Number(this.getKopfPos(m06.get('zMaxSchraubeID'), 'z'));

              p2.x = Number(xBezugspunkt);
              p2.y = Number(yBezugspunkt);
              p2.z = Number(this.getKopfPos(m06.get('zMaxSchraubeID'), 'z')) - Number(m06.get('schraubenAbstand2') / 10);
              break;
            case 'schraubenAbstand1':
              p1.x = Number(xBezugspunkt);
              p1.y = Number(yBezugspunkt);
              p1.z = Number(this.getKopfPos(m06.get('zMinSchraubeID'), 'z')) + Number(m06.get('schraubenAbstand1') / 10);

              p2.x = Number(xBezugspunkt);
              p2.y = Number(yBezugspunkt);
              p2.z = Number(this.getKopfPos(m06.get('zMinSchraubeID'), 'z'));
              break;
          }

          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            if (this.get('variable') === 'randAbstand2' || this.get('variable') === 'schraubenAbstand2') {
              p1.y = p1.y + abstandVomBauteil + hoeheMasskette;
              p2.y = p1.y;
            }

            drehwinkel.x = 0;
            drehwinkel.y = -w90Grad;
            drehwinkel.z = 0;
          } else if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') || bauteile.findBy('id', 'viewpointDefault').get('cameraProperties').objectAt(0).get('setBind')) {
            if (this.get('variable') === 'randAbstand2' || this.get('variable') === 'schraubenAbstand2') {
              p1.x = p1.x + abstandVomBauteil + hoeheMasskette;
              p2.x = p1.x;
            }

            drehwinkel.x = 0;
            drehwinkel.y = -w90Grad;
            drehwinkel.z = -w90Grad;
          }
        }

        cords = this.getMassketteKoordinaten(p1, p2, drehwinkel);
      }

      if (name !== 'nebenTraeger' && typ !== 'kontur' && item.get('typ') !== "verbindungsmittel") {
        cords = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9;
      }

      if (name === 'lastenPfeil') {
        cords = '0 0 0 0 1 0';
      }

      hwinkel = Number(m06.get('hWinkel')) * Math.PI / 180;
      vwinkel = Number(m06.get('vWinkel')) * Math.PI / 180;

      if (typ === 'hWinkel') {
        var radius = 1.5 + 0.1;
        var y = radius * Math.cos(hwinkel);
        var x = 0 - radius * Math.sin(hwinkel);

        cords = '0 0 0, 0 -1.6 0 , ' + x + ' ' + -y + ' 0';

        var c1 = this.getCords(radius, 0, 0, 0, hwinkel);
        var c2 = this.getCords(radius, 0, 0, 0, 0);

        cords = '0 0 0 ' + c1 + ' ' + c2;
      }

      if (typ === 'vWinkel') {
        var radius = 1 + 0.1;

        var c1 = this.getCords(radius, 0, 0, 0, 1.5708);
        var c2 = this.getCords(radius, 0, 0, vwinkel, 1.5708);

        cords = '0 0 0 ' + c1 + ' ' + c2;
      }

      if (typ === 'faserrichtung') {
        if (name === 'nebenTraeger') {
          x = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('x') / 10;
          y = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y') / 10;
          z = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 10;

          var xPlus = -(y * Math.tan(vwinkel));
          var zPlus = y / Math.tan(hwinkel);

          var deltaZ = x * Math.tan(vwinkel) * Math.tan(90 * Math.PI / 180 - hwinkel);

          p1 = this.getCords(x - 0.3, 0 + 0.45, -z / 2, vwinkel, hwinkel);
          p2 = this.getCords(x - 0.15, 0 + 0.3, -z / 2, vwinkel, hwinkel);
          p3 = this.getCords(x - 1, 0 + 0.3, -z / 2, vwinkel, hwinkel);
          p4 = this.getCords(x - 0.85, 0 + 0.15, -z / 2, vwinkel, hwinkel);

          cords = p1 + ', ' + p2 + ', ' + p3 + ', ' + p4;
        } else if (name = 'hauptTraeger') {
          x = bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10;
          y = 0;
          if (m06.get('istHt')) {
            z = bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('z') / 10;
            cords = -x / 2 + ' ' + (y + 0.45) + ' ' + (z / 2 - 0.85) + ', ' + -x / 2 + ' ' + (y + 0.3) + ' ' + (z / 2 - 1) + ', ' + -x / 2 + ' ' + (y + 0.3) + ' ' + (z / 2 - 0.25) + ', ' + -x / 2 + ' ' + (y + 0.15) + ' ' + (z / 2 - 0.4);
          } else {
            x = bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('y') / 10;
            z = bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10;
            cords = -x / 2 + ' ' + (y + 0.85) + ' ' + (z / 2 - 0.15) + ', ' + -x / 2 + ' ' + (y + 1) + ' ' + (z / 2 - 0.3) + ', ' + -x / 2 + ' ' + (y + 0.25) + ' ' + (z / 2 - 0.3) + ', ' + -x / 2 + ' ' + (y + 0.45) + ' ' + (z / 2 - 0.45);
          }
        }
      }

      return cords;
    }).property('model.firstObject.transformHelper', 'model.firstObject.viewpointHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.istHt', 'model.firstObject.skalierungsfaktor', 'model.firstObject.schraubenGekreuzt', 'model.firstObject.schraubenlisteAktualisiert'),

    koordinatenIndex: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var m06 = this.get('model').objectAt(0);
      var bauteile = m06.get('bauteile');
      var item = bauteile.findBy('id', name);
      var values = '';

      if (item.get('typ') === 'box') {
        if (name === 'hauptTraeger') {

          if (typ === 'breite' && bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            values = "0 0";
          } else if (typ === 'hoehe' && bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') && m06.get('istHt')) {
            values = '0 0';
          } else {
            values = "1 2 -1 0 3 -1 4 5 -1 6 7 -1 8 9 -1";
          }
        }

        if (name === 'nebenTraeger') {
          values = "1 2 -1 0 3 -1 4 5 -1 6 7 -1 8 9 -1";
        }

        if (typ === 'kontur') {
          values = "0 1 2 3 0 -1 4 5 6 7 4 -1 0 4 -1 1 5 -1 2 6 -1 3 7";
        }
      }

      if (item.get('typ') === 'lastenPfeil') {
        values = "0 1 -1";
      }

      if (typ === 'hWinkel') {
        values = "0 1 -1 0 2 -1";
      }
      if (typ === 'vWinkel') {
        values = "0 1 -1 0 2 -1";
      }

      if ((name.substring(0, 8) === 'schraube' || item.get('typ') === "verbindungsmittel") && m06.get('ergebnisGeladen')) {
        values = "1 2 -1 0 3 -1 4 5 -1 6 7 -1 8 9 -1";
        if (typ === 'xPos' && bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
          values = "0 0";
        } else if (typ === 'zPos' && bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
          values = "0 0";
        }
      }

      if (typ === 'faserrichtung') {
        values = "0 1 -1 1 2 -1 2 3 -1";
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.schraubenlisteAktualisiert'),

    istLastenpfeil: (function () {
      var istLastenpfeil = false;

      if (this.get('name') === 'lastenPfeil') {
        istLastenpfeil = true;
      }
      return istLastenpfeil;
    }).property(''),

    emissivecolor: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var m06 = this.get('model').objectAt(0);

      var emsvcolor = '0 0 0';

      if (m06.get('istHt') === true && m06.get('htbreiteAktiv') === true && name === 'hauptTraeger' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (m06.get('istHt') === true && m06.get('hthoeheAktiv') === true && name === 'hauptTraeger' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (m06.get('istHt') === false && m06.get('hthoeheAktiv') === true && name === 'hauptTraeger' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (m06.get('istHt') === false && m06.get('htbreiteAktiv') === true && name === 'hauptTraeger' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (m06.get('ntbreiteAktiv') === true && name === 'nebenTraeger' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (m06.get('nthoeheAktiv') === true && name === 'nebenTraeger' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (m06.get('hWinkelAktiv') === true && typ === 'hWinkel') {
        emsvcolor = '1 0 0';
      }

      if (m06.get('vWinkelAktiv') === true && typ === 'vWinkel') {
        emsvcolor = '1 0 0';
      }

      if (m06.get('bemessungslastAktiv') === true && name === 'lastenPfeil') {
        emsvcolor = '1 0 0';
      }

      return emsvcolor;
    }).property('model.firstObject.htbreiteAktiv', 'model.firstObject.hthoeheAktiv', 'model.firstObject.ntbreiteAktiv', 'model.firstObject.nthoeheAktiv', 'model.firstObject.hWinkelAktiv', 'model.firstObject.vWinkelAktiv', 'model.firstObject.bemessungslastAktiv'),

    getKopfPos: function getKopfPos(name, achse) {

      var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);

      var verschiebung = item.get('translations').objectAt(0).get(achse) / 10;

      var schraubenlaenge = item.get('screwProperties').objectAt(0).get('length') / 10;

      // var schraubenlaenge = Math.abs(item.get('translations').objectAt(0).get('y'))/10 / Math.cos(0.7854);
      //
      var richtung = 1;
      if (achse === 'z') {
        richtung = -1;
      }
      //
      // var kopfPos = verschiebung + richtung* item.get('richtungsvektor').objectAt(0).get(achse)*schraubenlaenge;

      var kopfPos = verschiebung + richtung * item.get('richtungsvektor').objectAt(0).get(achse) * schraubenlaenge;

      // console.log("Name: "+name+" Achse: "+achse+" ::: Kopfpos = "+kopfPos+" = "+verschiebung+" + "+item.get('richtungsvektor').objectAt(0).get(achse)+" * "+schraubenlaenge);

      return kopfPos;
    },

    getKonturKoordinaten: function getKonturKoordinaten(x, y, z) {

      var p0 = -x / 2 + ' ' + 0 + ' ' + -z / 2;
      var p1 = -x / 2 + ' ' + 0 + ' ' + z / 2;
      var p2 = x / 2 + ' 0 ' + z / 2;
      var p3 = x / 2 + ' 0 ' + -z / 2;
      var p4 = -x / 2 + ' ' + y + ' ' + -z / 2;
      var p5 = -x / 2 + ' ' + y + ' ' + z / 2;
      var p6 = x / 2 + ' ' + y + ' ' + z / 2;
      var p7 = x / 2 + ' ' + y + ' ' + -z / 2;
      var p8 = 0;
      var p9 = 0;

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9;

      return koordinaten;
    },

    getMassketteKoordinaten: function getMassketteKoordinaten(punkt1, punkt2, drehwinkel) {

      var name = this.get('name');
      var typ = this.get('typ');

      var skalierungsfaktor = 1;

      var b05 = 0.05 * skalierungsfaktor;
      var b1 = 0.1 * skalierungsfaktor;
      var b5 = 0.5 * skalierungsfaktor;
      var b45 = b5 - b05;
      var b55 = b5 + b05;
      var b6 = 0.6 * skalierungsfaktor;

      var hwinkel = Number(this.get('model').objectAt(0).get('hWinkel')) * Math.PI / 180;
      var vwinkel = Number(this.get('model').objectAt(0).get('vWinkel')) * Math.PI / 180;

      var dp = new Object();
      var deltaX = 0;
      var deltaY = 0;

      var p0 = Number(punkt1.x) + ' ' + punkt1.y + ' ' + punkt1.z;
      var p1 = Number(punkt2.x) + ' ' + (Number(punkt2.y) + Number(deltaY)) + ' ' + punkt2.z;

      dp = this.drehungXAchse({ x: 0, y: b6, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p2 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);
      var p3 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);

      dp = this.drehungXAchse({ x: b1, y: b5, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p4 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);

      dp = this.drehungXAchse({ x: -b1, y: b5, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p5 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);

      dp = this.drehungXAchse({ x: -b05, y: b45, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p6 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);
      var p8 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);

      dp = this.drehungXAchse({ x: b05, y: b55, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p7 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);
      var p9 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9;

      return koordinaten;
    },

    drehungXAchse: function drehungXAchse(v, omega) {
      var r = new Object();
      r.x = v.x;
      r.y = v.y * Math.cos(omega) - v.z * Math.sin(omega);
      r.z = v.y * Math.sin(omega) + v.z * Math.cos(omega);
      return r;
    },

    drehungYAchse: function drehungYAchse(v, omega) {
      var r = new Object();
      r.x = v.x * Math.cos(omega) + v.z * Math.sin(omega);
      r.y = v.y;
      r.z = -v.x * Math.sin(omega) + v.z * Math.cos(omega);
      return r;
    },

    drehungZAchse: function drehungZAchse(v, omega) {
      var r = new Object();
      r.x = v.x * Math.cos(omega) - v.y * Math.sin(omega);
      r.y = v.x * Math.sin(omega) + v.y * Math.cos(omega);
      r.z = v.z;
      return r;
    },

    getCords: function getCords(x, y, z, vwinkel, hwinkel) {

      var yNeu = y / Math.cos(vwinkel);
      var zNeu = z / Math.sin(hwinkel);
      var p = new Object();

      var yNeu = y / Math.cos(vwinkel);
      var zNeu = z / Math.sin(hwinkel);

      p.x = (-x * Math.sin(hwinkel) - z * Math.cos(hwinkel)) * Math.cos(vwinkel) - y * Math.sin(vwinkel);
      p.y = yNeu - (x * Math.sin(hwinkel) + z * Math.cos(hwinkel)) * Math.sin(vwinkel) - (yNeu - y * Math.cos(vwinkel));
      p.z = -zNeu / 2 + x * Math.cos(hwinkel) + (zNeu / 2 - z * Math.sin(hwinkel));

      // p.x = (x*Math.cos(vwinkel) - (y) * Math.sin(vwinkel)) * Math.sin(hwinkel) - (z) * Math.cos(hwinkel);
      // p.y = ((p.x)*Math.sin(vwinkel)) + y*Math.cos(vwinkel);
      // p.z = ((-x)*Math.cos(hwinkel) - (z) * Math.sin(hwinkel));

      var returnString = p.x + ' ' + p.y + ' ' + p.z;

      return returnString;
    }
  });

  exports['default'] = Transform;

});