define('m20/controllers/ergebnis', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    kompaktergebnis: "",
    // pdfErstellt: false,
    // pdfErstellt: null,

    // pdfErstellt: function() {
    //   return this.controllerFor('application').get('model').application.objectAt(0).get('pdfErstellt');
    // }.property('pdfErstellt'),

    pdfErstellt: false,
    pdfLink: "",
    pdfUnderConstruction: false,
    pdfFileName: "",

    init: function init() {

      this._super();

      var self = this;

      var application = this.controllerFor('application');

      if (application.get('lasteinwirkungInvalid') || application.get('bauteileInvalid')) {
        this.transitionToRoute('projektdaten');
      } else {
        console.log("lasteinwirkung ist valid");
      }
    },

    uebertrageBild: function uebertrageBild(bildIndex, filename, imgData) {

      var self = this;

      var application = this.controllerFor('application');
      var server = application.get('server');
      var pfad = application.get('pfad');

      console.log('sende image: ' + filename);

      var url = "";

      if (application.get('environment') === "offline") {
        url = "http://localhost:50002/speichereGrafik";
      } else {
        var environment = externalConfig.environments[application.get('environment')];
        url = externalConfig[environment].uriImageservice;
      }

      $.ajax({
        type: "POST",
        url: url,
        data: {
          name: filename,
          b64: imgData
        }
      }).done(function (data) {
        if (bildIndex === 4) {
          self.set('triggerPDFabrufen', !self.get('triggerPDFabrufen'));
          self.pdfAbrufen();
        }
      });
    },

    pdfAbrufen: function pdfAbrufen() {

      console.log('PDF Abruf');

      var self = this;

      var application = self.controllerFor('application');
      var applicationdata = application.get('model').application.objectAt(0);
      var cid = applicationdata.get('Calculation_ID');
      var selectedScrew = applicationdata.get('selectedScrew');

      var downloadcenter = self.controllerFor('downloadcenter');

      var projektdatendata = application.get('model').projektdaten.objectAt(0);
      var projektdatendataJSON = JSON.stringify(projektdatendata);

      var server = application.get('server');
      var pfad = application.get('pfad');

      $.ajax({
        type: "POST",
        url: server + "erzeugeAusgabe/",
        data: {
          cid: cid,
          projektdaten: projektdatendataJSON,
          artnr: selectedScrew
        }
      }).done(function (data, statusText, xhr) {

        self.controllerFor('supercontroller').logaufruf("erzeugeAusgabe", xhr.status);

        self.set('pdfErstellt', true);
        applicationdata.set('pdfErstellt', true);
        self.set('pdfUnderConstruction', false);
        self.set('pdfLink', data);
        self.set('pdfFileName', cid + ".pdf");

        downloadcenter.set('pdfFile', data);
        downloadcenter.set('pdfErstellt', true);

        console.log(data);
      });
    },

    setPNGimageData: function setPNGimageData() {

      var self = this;

      var i = 0;

      var application = this.controllerFor('application');
      var applicationdata = application.get('model').application.objectAt(0);
      var x3d = application.get('model').m06default.objectAt(0);
      var cid = applicationdata.get('Calculation_ID');

      x3d.set('istGitterModell', false);
      document.getElementById('navInfo').setAttribute('transitiontime', '0');

      self.controllerFor('application').zentriertObjekt();

      var viewpointarray = Array('viewpointDefault', 'viewpointLinks', 'viewpointOben', 'viewpointFront');
      var imageNameArray = Array('ImgNameIsoView_', 'ImgNameLeftView_', 'ImgNameTopView_', 'ImgNameFrontView_');

      var pngImages = [{ id: 0, typ: "viewpointDefault", name: "", imgData: "", msg: "" }, { id: 1, typ: "viewpointLinks", name: "", imgData: "", msg: "" }, { id: 2, typ: "viewpointOben", name: "", imgData: "", msg: "" }, { id: 3, typ: "viewpointFront", name: "", imgData: "", msg: "" }];

      i = 0;
      var myInterval = setInterval(function () {

        console.log(i + ' : ' + viewpointarray[i]);

        if (i === 0) {
          document.getElementById('viewpointDefault').setAttribute('set_bind', 'true');
          x3d.set('abstaendeAnzeigen', false);
          x3d.get('bauteile').findBy('id', x3d.get('lastViewpoint')).get('cameraProperties').objectAt(0).set('setBind', false);
          x3d.set('lastViewpoint', 'viewpointDefault');
          x3d.get('bauteile').findBy('id', 'viewpointDefault').get('cameraProperties').objectAt(0).set('setBind', true);
        }

        if (i > 0) {
          document.getElementById('viewpointDefault').setAttribute('set_bind', 'true');
          var imgUrl = document.getElementById("x3d").runtime.getScreenshot();
          var imgData = imgUrl.toString().replace('data:image/png;base64,', '');
          var filename = imageNameArray[i - 1] + cid + '.png';

          x3d.set('abstaendeAnzeigen', true);

          self.uebertrageBild(i, filename, imgData);
        }

        if (i === 4) {
          document.getElementById('viewpointDefault').setAttribute('set_bind', true);
          self.controllerFor('application').zentriertObjekt();
          x3d.get('bauteile').findBy('id', x3d.get('lastViewpoint')).get('cameraProperties').objectAt(0).set('setBind', false);
          x3d.set('lastViewpoint', 'viewpointDefault');
          x3d.get('bauteile').findBy('id', 'viewpointDefault').get('cameraProperties').objectAt(0).set('setBind', true);
          x3d.set('viewpointHelper', !x3d.get('viewpointHelper'));
          document.getElementById('navInfo').setAttribute('transitiontime', '1');
          clearInterval(myInterval);
        }
        i++;

        if (i < 5) {

          document.getElementById(viewpointarray[i - 1]).setAttribute('set_bind', 'true');
          x3d.get('bauteile').findBy('id', x3d.get('lastViewpoint')).get('cameraProperties').objectAt(0).set('setBind', false);
          x3d.set('lastViewpoint', viewpointarray[i - 1]);
          x3d.get('bauteile').findBy('id', viewpointarray[i - 1]).get('cameraProperties').objectAt(0).set('setBind', true);
          x3d.set('viewpointHelper', !x3d.get('viewpointHelper'));
        }
      }, 500);
    },

    actions: {
      createPDF: function createPDF(event) {

        var self = this;

        self.setPNGimageData();

        if (self.get('pdfErstellt') == false) {

          self.set('pdfUnderConstruction', true);
        }
      },

      showPrintView: function showPrintView(event) {

        var self = this;

        var printview = window.open("", "Druckansicht", "width=600, height=800");

        printview.document.write("<font face='Arial'><table><tr><td width='200' height='80'><center><img src='assets/images/wuerth.jpg' width='150'></center></td><td width='100%' bgcolor='#ff0000'><font color='#ffffff'>Würth bemessungssoftware</font></td></tr></table>");
        printview.document.write("<hr></hr>");
        printview.document.write("<h2>M06</h2>");
        printview.document.write(self.kompaktergebnis);
        printview.document.write("<hr></hr>");
        printview.document.write("<img src='assets/images/wuerth_group.png' width='75'> <small>(c) SWG Engineering 2016</small></font>");
      },

      setKompaktergebnis: function setKompaktergebnis(event) {
        var self = this;
        // var div = document.createElement('div');
        //
        // div.className = 'row';
        //
        // div.innerHTML = self.kompaktergebnis;
        // document.getElementById('kompaktergebnis').appendChild(div);

        $("#kompaktergebnis").html(self.kompaktergebnis);
      },

      resetErstellt: function resetErstellt(identifier, value, filePath) {
        var self = this;

        var link = document.createElement('a');
        link.href = filePath;
        link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
        link.click();

        self.set(identifier + 'Erstellt', value);
        self.controllerFor('application').get('model').application.objectAt(0).set(identifier + 'Erstellt', value);
        self.controllerFor('downloadcenter').set(identifier + 'Erstellt', value);
      }

    }

  });

});