define('m20/components/tex-t', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var x3dText = Ember['default'].Component.extend({
    tagName: 'transform',
    layoutName: 'tex-t',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    austrittspunktRechts: false,

    translation: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var m06 = this.get('model').objectAt(0);
      var bauteile = m06.get('bauteile');

      var x = 0;
      var y = 0;
      var z = 0;

      var cords;

      var skalierungsfaktor = m06.get('skalierungsfaktor');

      var item = m06.get('bauteile').findBy('id', name);

      var hoeheMasskette = 0.5;
      var abstandVomBauteil = 0.15;
      var abstandVonDerMasslinie = 0.05;
      var korrektur = 0.05;

      if (name === 'nebenTraeger' || name === 'hauptTraeger') {
        x = item.get('boxsizes').objectAt(0).get('x') / 10;
        y = item.get('boxsizes').objectAt(0).get('y') / 10;
        z = item.get('boxsizes').objectAt(0).get('z') / 10;

        if (name === 'hauptTraeger') {
          if (m06.get('istHt') === false) {
            x = item.get('boxsizes').objectAt(0).get('y') / 10;
            y = item.get('boxsizes').objectAt(0).get('z') / 10;
            z = item.get('boxsizes').objectAt(0).get('x') / 10;
          }

          if (typ === 'breite') {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              cords = 0 + ' ' + 0 + ' ' + (z / 2 + hoeheMasskette - abstandVonDerMasslinie);
            } else {
              cords = 0 + ' ' + (-hoeheMasskette + abstandVonDerMasslinie) + ' ' + z / 2;
            }
          } else {
            if (m06.get('istHt') === true) {
              if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                cords = x / 2 + ' ' + y / 2 + ' ' + (z / 2 + hoeheMasskette + abstandVonDerMasslinie);
              } else {
                cords = x / 2 + hoeheMasskette + abstandVonDerMasslinie + ' ' + y / 2 + ' ' + z / 2;
              }
            } else {
              if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                cords = x / 2 + hoeheMasskette + abstandVonDerMasslinie + ' ' + 0 + ' ' + 0;
              } else {
                cords = x / 2 + ' ' + (-hoeheMasskette + abstandVonDerMasslinie) + ' ' + 0;
              }
            }
          }
        }

        if (name === 'nebenTraeger') {

          var hwinkel = Number(m06.get('hWinkel'));
          var vwinkel = 0 + Number(m06.get('vWinkel'));

          hwinkel = hwinkel * Math.PI / 180;
          vwinkel = vwinkel * Math.PI / 180;

          var xPlus = y / Math.tan(vwinkel);
          var zPlus = y / Math.tan(hwinkel);

          if (typ === 'breite') {

            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              cords = this.getCords(x + hoeheMasskette + abstandVonDerMasslinie, y, 0, vwinkel, hwinkel);
            } else {
              cords = this.getCords(x, y + hoeheMasskette + abstandVonDerMasslinie, 0, vwinkel, hwinkel);
            }
          } else {
            if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
              cords = this.getCords(x + hoeheMasskette + abstandVonDerMasslinie, y / 2, z / 2, vwinkel, hwinkel);
            } else {
              cords = this.getCords(x, y / 2, z / 2 + hoeheMasskette + abstandVonDerMasslinie, vwinkel, hwinkel);
            }
          }
        }
      }

      if (typ === 'hWinkel') {
        var radius = 2.1;
        var winkel = Number(m06.get('hWinkel') / 2) * Math.PI / 180;
        var x = 0 - radius * Math.sin(winkel);
        var y = 0 - radius * Math.cos(winkel);

        cords = x + ' ' + y + ' 0';
      }

      if (typ === 'vWinkel') {
        var radius = 1.3;
        var winkel = Number(m06.get('vWinkel') / 2) * Math.PI / 180;
        var x = -(radius * Math.cos(winkel));
        var y = 0 - radius * Math.sin(winkel);
        cords = x + ' ' + y + ' 0';
      }

      if ((name.substring(0, 8) === 'schraube' || item.get('typ') === "verbindungsmittel") && m06.get('ergebnisGeladen')) {

        var xKopfPos = Number(this.getSchraubeKopfPos(name, 'x')) / 10;
        var zKopfPos = this.getSchraubeKopfPos(name, 'z') / 10;

        if (this.get('typ') === 'xPos') {
          if (m06.get('istHt')) {
            z = bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('z') / 20;
          } else {
            z = bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('y') / 20;
          }

          if (this.austrittspunktRechts) {
            cords = bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10 + 1 + (+abstandVomBauteil + hoeheMasskette + abstandVonDerMasslinie) + ' ' + -xKopfPos / 2 + ' ' + (-z - 0.3);
          } else {
            if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
              cords = xKopfPos / 2 + ' ' + (hoeheMasskette + abstandVonDerMasslinie) + ' ' + (-z - 0.3);
            } else {
              cords = xKopfPos / 2 + ' 0 ' + (-z - abstandVomBauteil - hoeheMasskette - abstandVonDerMasslinie);
            }
          }
        }

        if (this.get('typ') === 'zPos') {

          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') && !m06.get('istHt')) {
            x = m06.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10 + 0.5;
          } else if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            x = m06.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10 + 0.3;
          } else {
            x = m06.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10;
          }

          var delta = 0;
          var zPos = 0;
          var bezugspunkt = 0;

          var traegerbreite = Number(m06.get('bauteile').findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z')) / 10;
          var winkel = Number(m06.get('hWinkel')) * Math.PI / 180;
          bezugspunkt = traegerbreite / Math.sin(winkel) / 2;

          switch (this.get('variable')) {
            case 'randAbstand1':

              if (Number(this.getKopfPos(m06.get('zMinSchraubeID'), 'z')) < -bezugspunkt) {
                zPos = -bezugspunkt - Number(m06.get('randAbstand1')) / 10;
              } else {
                zPos = -bezugspunkt;
              }
              zPos = zPos - 0.2;
              delta = 0;
              break;
            case 'randAbstand2':

              if (Number(this.getKopfPos(m06.get('zMaxSchraubeID'), 'z')) > bezugspunkt) {
                zPos = bezugspunkt + Number(m06.get('randAbstand2')) / 10;
              } else {
                zPos = bezugspunkt;
              }
              zPos = zPos + 0.2;

              delta = hoeheMasskette + abstandVomBauteil;
              break;
            case 'schraubenAbstand1':

              if (Number(this.getKopfPos(m06.get('zMinSchraubeID'), 'z')) < -bezugspunkt) {
                zPos = -bezugspunkt + Number(m06.get('schraubenAbstand1')) / 10 - Number(m06.get('randAbstand1')) / 10;
              } else {
                zPos = -bezugspunkt + Number(m06.get('schraubenAbstand1')) / 10 + Number(m06.get('randAbstand1')) / 10;
              }

              zPos = zPos + 0.2;

              // zPos = -bezugspunkt + Number(m06.get('schraubenAbstand1'))/20 + Number(m06.get('randAbstand1'))/10;
              delta = 0;
              break;
            case 'schraubenAbstand2':

              if (Number(this.getKopfPos(m06.get('zMaxSchraubeID'), 'z')) > bezugspunkt) {
                zPos = bezugspunkt - Number(m06.get('schraubenAbstand2')) / 10 + Number(m06.get('randAbstand2')) / 10;
              } else {
                zPos = bezugspunkt - Number(m06.get('schraubenAbstand2')) / 10 - Number(m06.get('randAbstand2')) / 10;
              }

              zPos = zPos - 0.2;

              // zPos = bezugspunkt - Number(m06.get('schraubenAbstand2'))/20 - Number(m06.get('randAbstand2'))/10;
              delta = +hoeheMasskette + abstandVomBauteil;
              break;
          }

          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            cords = x + ' ' + (y + abstandVomBauteil + 2 * hoeheMasskette + abstandVonDerMasslinie + delta) + ' ' + zPos;
          } else {
            cords = x + delta + abstandVomBauteil + hoeheMasskette + abstandVonDerMasslinie + ' ' + y + ' ' + zPos;
          }
        }
      } // Ende Ergebnismaßketten

      if (name === 'lastenPfeil') {
        cords = 0 + ' ' + 1.3 + ' 0';
      }

      return cords;
    }).property('model.firstObject.transformHelper', 'model.firstObject.viewpointHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.istHt', 'model.firstObject.skalierungsfaktor', 'model.firstObject.schraubenGekreuzt', 'model.firstObject.schraubenlisteAktualisiert'),

    rotation: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var m06 = this.get('model').objectAt(0);
      var bauteile = m06.get('bauteile');
      var values;

      var hwinkel = Number(m06.get('hWinkel')) * Math.PI / 180;
      var vwinkel = Number(m06.get('vWinkel')) * Math.PI / 180;

      var item = m06.get('bauteile').findBy('id', name);

      if (name === 'hauptTraeger') {
        if (typ === 'breite') {
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            values = '1 0 0 -1.5707';
          } else {
            values = '0 0 0 -1.5707';
          }
        } else {
          if (m06.get('istHt')) {
            if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              values = '-1 1 1 4.18879';
            } else {
              values = '0 0 1 -1.5707';
            }
          } else {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              values = '1 1 1 4.18879';
            } else {
              values = '0 1 0 -1.5707';
            }
          }
        }
      }

      if (name === 'nebenTraeger') {
        if (typ === 'breite') {
          // values = '0 1 0 '+ -hwinkel;

          if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
            values = '0 0 0 0';
          } else if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            var x = 1,
                y = -1,
                z = -1;

            var dX = Math.sin(hwinkel);
            var dZ = -Math.cos(hwinkel);

            values = x + ' ' + y + ' ' + z + ' 4.1888';

            // console.log(values);
          } else {
              values = '0 1 0 -1.5707';
            }
        } else {

          if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
            values = '0 0 1 1.5708';
          } else if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            values = '1 0 0 -1.5708';
          } else {
            values = '1 1 -1 4.1888';
          }
        }
      }

      if (typ === 'hWinkel') {
        values = '0 0 0 0';
      }

      if (typ === 'vWinkel') {
        values = '0 0 0 0';
      }

      if (name.substring(0, 8) === 'schraube' || item.get('typ') === "verbindungsmittel") {

        if (this.get('typ') === 'xPos') {
          if (this.austrittspunktRechts) {
            values = '0 0 1 -1.5707';
          } else {
            if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
              values = '0 0 0 0';
            } else {
              values = '1 0 0 -1.5707';
            }
          }
        }

        if (this.get('typ') === 'zPos') {
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            values = '0 1 0 -1.5707';
          } else {
            values = '1 1 1 4.2';
          }
        }
      }

      if (name === 'lastenPfeil') {
        values = '0 0 0 0';
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.viewpointHelper', 'model.firstObject.istHt'),

    wert: (function () {

      var name = this.get('name');
      var typ = this.get('typ');
      var m06 = this.get('model').objectAt(0);
      var bauteile = m06.get('bauteile');
      var value = ' ';

      var item = m06.get('bauteile').findBy('id', name);

      if (item.get('typ') === "box") {

        if (name === 'nebenTraeger') {
          if (typ === 'hoehe' && m06.get('ntHoeheEingetragen')) {
            value = item.get('boxsizes').objectAt(0).get('y');
          } else if (typ === 'breite' && m06.get('ntBreiteEingetragen')) {
            value = item.get('boxsizes').objectAt(0).get('z');
          } else {
            value = ' ';
          }
        }

        if (name === 'hauptTraeger') {
          if (typ === 'hoehe') {
            if (m06.get('istHt') && !bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') && m06.get('htHoeheEingetragen')) {
              value = item.get('boxsizes').objectAt(0).get('y');
            } else if (!m06.get('istHt') && m06.get('htBreiteEingetragen')) {
              value = item.get('boxsizes').objectAt(0).get('x');
            } else {
              value = ' ';
            }
          } else if (typ === 'breite') {
            if (m06.get('istHt') && !bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') && m06.get('htBreiteEingetragen')) {
              value = item.get('boxsizes').objectAt(0).get('x');
            } else if (!m06.get('istHt') && m06.get('htHoeheEingetragen') && !bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              value = item.get('boxsizes').objectAt(0).get('y');
            } else {
              value = ' ';
            }
          } else {
            value = ' ';
          }
        }
      }

      if (typ === 'vWinkel' && (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') || bauteile.findBy('id', 'viewpointDefault').get('cameraProperties').objectAt(0).get('setBind'))) {
        value = m06.get('vWinkel') + '°';
      }

      if (typ === 'hWinkel') {
        value = m06.get('hWinkel') + '°';
      }

      if ((name.substring(0, 8) === 'schraube' || item.get('typ') === "verbindungsmittel") && m06.get('ergebnisGeladen')) {
        if (this.get('typ') === 'xPos' && !bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
          var xKopfPos = this.getSchraubeKopfPos(name, 'x');
          value = Math.abs(xKopfPos.toFixed(1));
        }

        var bezugspunkt = 0;

        var traegerbreite = Number(m06.get('bauteile').findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z')) / 10;
        var winkel = Number(m06.get('hWinkel')) * Math.PI / 180;
        bezugspunkt = traegerbreite / Math.sin(winkel) / 2;

        if (this.get('typ') === 'zPos' && !bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
          switch (this.get('variable')) {
            case 'randAbstand1':
              value = m06.get('randAbstand1');
              value = parseFloat(value).toFixed(1);
              break;
            case 'randAbstand2':
              value = m06.get('randAbstand2');
              value = parseFloat(value).toFixed(1);
              break;
            case 'schraubenAbstand1':

              if (Number(this.getKopfPos(m06.get('zMinSchraubeID'), 'z')) < -bezugspunkt) {
                value = Math.abs(Number(m06.get('randAbstand1')) - Number(m06.get('schraubenAbstand1')));
              } else {
                value = m06.get('schraubenAbstand1');
              }

              // value = m06.get('schraubenAbstand1');
              value = parseFloat(value).toFixed(1);
              break;
            case 'schraubenAbstand2':

              if (Number(this.getKopfPos(m06.get('zMaxSchraubeID'), 'z')) > bezugspunkt) {
                value = Math.abs(Number(m06.get('randAbstand2')) - Number(m06.get('schraubenAbstand2')));
              } else {
                value = m06.get('schraubenAbstand2');
              }

              // value = m06.get('schraubenAbstand2');
              value = parseFloat(value).toFixed(1);
              break;
          }

          // console.log(name+' : '+value);
        }
      }

      if (name === 'lastenPfeil') {
        value = m06.get('bemessungslast');
        value = parseFloat(value).toFixed(1) + ' kN';
      }

      // console.log('name: '+name+' ::: typ: '+typ+' ::: variable: '+this.get('variable')+' ::: value: '+value);

      return value;
    }).property('model.firstObject.transformHelper', 'model.firstObject.masskettenHelper'), // }.property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt', 'model.firstObject.schraubenlisteAktualisiert', 'model.firstObject.skalierungsfaktor'),

    diffusecolor: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var variable = this.get('variable');
      var m06 = this.get('model').objectAt(0);

      var emsvcolor = '0 0 0';

      if (m06.get('istHt') === true && m06.get('htbreiteAktiv') === true && name === 'hauptTraeger' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (m06.get('istHt') === true && m06.get('hthoeheAktiv') === true && name === 'hauptTraeger' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (m06.get('istHt') === false && m06.get('hthoeheAktiv') === true && name === 'hauptTraeger' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (m06.get('istHt') === false && m06.get('htbreiteAktiv') === true && name === 'hauptTraeger' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (m06.get('ntbreiteAktiv') === true && name === 'nebenTraeger' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (m06.get('nthoeheAktiv') === true && name === 'nebenTraeger' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (m06.get('hWinkelAktiv') === true && typ === 'hWinkel') {
        emsvcolor = '1 0 0';
      }

      if (m06.get('vWinkelAktiv') === true && typ === 'vWinkel') {
        emsvcolor = '1 0 0';
      }

      if (m06.get('bemessungslastAktiv') === true && name === 'lastenPfeil') {
        emsvcolor = '1 0 0';
      }

      return emsvcolor;
    }).property('model.firstObject.htbreiteAktiv', 'model.firstObject.hthoeheAktiv', 'model.firstObject.ntbreiteAktiv', 'model.firstObject.nthoeheAktiv', 'model.firstObject.hWinkelAktiv', 'model.firstObject.vWinkelAktiv', 'model.firstObject.bemessungslastAktiv'),

    textgroesse: (function () {
      var skalierungsfaktor = this.get('model').objectAt(0).get('skalierungsfaktor');
      return 0.3 * skalierungsfaktor;
    }).property('model.firstObject.skalierungsfaktor', 'model.firstObject.transformHelper'),

    getSchraubeKopfPos: function getSchraubeKopfPos(name, achse) {

      var m06 = this.get('model').objectAt(0);
      var item = m06.get('bauteile').findBy('id', name);

      var schraubenlaenge = item.get('screwProperties').objectAt(0).get('length');
      // var schraubenlaenge = Math.abs(item.get('translations').objectAt(0).get('y')) / Math.cos(0.7854);

      var kopfPos = item.get('translations').objectAt(0).get(achse) + item.get('richtungsvektor').objectAt(0).get(achse) * schraubenlaenge;

      return kopfPos;
    },

    getCords: function getCords(x, y, z, vwinkel, hwinkel) {

      var yNeu = y / Math.cos(vwinkel);
      var zNeu = z / Math.sin(hwinkel);
      var p = new Object();

      var yNeu = y / Math.cos(vwinkel);
      var zNeu = z / Math.sin(hwinkel);

      p.x = (-x * Math.sin(hwinkel) - z * Math.cos(hwinkel)) * Math.cos(vwinkel) - y * Math.sin(vwinkel);
      p.y = yNeu - (x * Math.sin(hwinkel) + z * Math.cos(hwinkel)) * Math.sin(vwinkel) - (yNeu - y * Math.cos(vwinkel));
      p.z = -zNeu / 2 + x * Math.cos(hwinkel) + (zNeu / 2 - z * Math.sin(hwinkel));

      var returnString = p.x + ' ' + p.y + ' ' + p.z;

      return returnString;
    },

    getKopfPos: function getKopfPos(name, achse) {

      var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);

      var verschiebung = item.get('translations').objectAt(0).get(achse) / 10;

      var schraubenlaenge = item.get('screwProperties').objectAt(0).get('length') / 10;
      // var schraubenlaenge = Math.abs(item.get('translations').objectAt(0).get('y')) / 10 / Math.cos(0.7854);
      //
      var richtung = 1;
      if (achse === 'z') {
        richtung = -1;
      }
      //
      // var kopfPos = verschiebung + richtung * item.get('richtungsvektor').objectAt(0).get(achse) * schraubenlaenge;

      var kopfPos = verschiebung + richtung * item.get('richtungsvektor').objectAt(0).get(achse) * schraubenlaenge;

      return kopfPos;
    },

    postionDurchSkalierung: function postionDurchSkalierung(richtung) {
      var x3d = this.get('model').objectAt(0);
      var skalierungsfaktor = x3d.get('skalierungsfaktor') + 0.6;
      // var value = ((0.6*skalierungsfaktor) + richtung*(0.14/skalierungsfaktor));
      var value = 0.2 * skalierungsfaktor;
      return 0;
    }

  });

  exports['default'] = x3dText;

});