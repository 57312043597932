define('m20/locales/translations', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {
    berechnung_pdf: "#Berechnung als PDF",
    hausnummer: "#Hausnummer",
    gew_schraube: "#Gewählt",
    email: "#Email",
    beschreibung: "#Beschreibung",
    lasteinwirkung2: "#Lasteinwirkung",
    tt_text: "#Ausgabe der Statikempfehlung im PDF-Format",
    ergebnis: "#Ergebnis",
    eingabeerforderlich: "#Eingabe erforderlich !",
    plz: "#Postleitzahl",
    kompakterg: "#Kompaktergebnis",
    eingabe_ok: "#Korrekte Eingabe",
    msg: "#Unzulässige Schrauben einblenden",
    bezeichnung: "#Bezeichnung",
    tel: "#Telefon",
    ort: "#Ort",
    land: "#Land",
    projektdaten: "#Projektdaten",
    fax: "#Fax",
    kdnum: "#Kundennummer",
    verbindungsmittel: "#Verbindungsmittel",
    def_bereich: "#Definitionsbereich",
    artnr: "#Artikelnummer",
    nkl: "#Nutzungsklasse",
    name: "#Name",
    adresse: "#Straße, Hausnummer",
    verarbeiter: "#Verarbeiter/Auftraggeber",
    vbms: "#Verbindungsmittel",
    nkl3: "#Nutzungsklasse 3",
    nkl2: "#Nutzungsklasse 2",
    nkl1: "#Nutzungsklasse 1",
    position: "#Position",
    strasse: "#Straße",
    antrieb: "#Antrieb",
    bauvorhaben: "#Bauvorhaben",
    vbm: "#Verbindungsmittel",
    auftraggeber: "#Auftraggeber",
    bemessungssoftware: "#Würth Holzbausoftware",
    wertebereich: "#Zulässiger Wertebereich:",
    ergebnisanzeigen: "#Ergebnis anzeigen:",
    einstellung: "#Einstellung",
    spracheinstellungen: "#Spracheinstellungen",
    eingabe: "#Eingabe",
    ausgabe: "#Ausgabe",
    landeseinstellungen: "#Landeseinstellungen",
    bemessungsvorschrift: "#Bemessungsvorschrift",
    liefersortiment: "#Liefersortiment",
    produktkatalog: "#Produktkatalog",
    produktinfo: "#Prodktinformation",
    eta: "#ETA",
    cad: "#CAD",
    impressum: "#Impressum",
    extras: "#Extras",
    bemerkungen: "#Bemerkungen",
    bemerkungstext: "#Bemerkungstext",
    eingabedaten: "#Eingabedaten",
    berechnungalspdf: "#Berechnung als PDF",
    berechnungspeichern: "#Berechnung speichern",
    berechnungoeffnen: "#Berechnung öffnen",
    downloadinstructions1: "#Linksklick auf Icons: Datei in neuem Fenster öffnen.",
    downloadinstructions3: "#Geben Sie einen Dateinamen an, unter welchem Sie die oben ausgewählten Dateien als zusammengefasstes Paket herunterladen möchten.",
    eingabedatennichtgespeichert: "#Eingabedaten wurden noch nicht gespeichert.",
    pdfnochnichterstellt: "#Es wurde noch keine PDF erzeugt.",
    dlfilename: "#Dateiname",
    dlpaketErstellen: "#Downloadpaket erstellen",
    nochkeineschraubegewaehlt: "#Es wurde noch keine Schraube ausgewählt.",
    nochkeindlerzeugt: "#Es wurde noch kein Downloadpaket erstellt.",
    neueberechnung: "#Neue Berechnung",
    kontakt: "#Kontakt",
    herausgeber: "#Herausgeber",
    registergericht: "#Registergericht",
    geschaeftsfuehrer: "#Geschäftsführer",
    entwickeltvon: "#Entwickelt von",
    dansk: "#Dänisch",
    deutsch: "#Deutsch",
    englisch: "#Englisch",
    franzoesisch: "#Französisch",
    italienisch: "#Italienisch",
    nederlands: "#Holländisch",
    polnisch: "#Polnisch",
    espanol: "#Spanisch",
    suomi: "#Finnisch",
    schwedisch: "#Schwedisch",
    schweiz: "#Schweiz",
    daenemark: "#Dänemark",
    deutschland: "#Deutschland",
    oesterreich: "#Österreich",
    england: "#England",
    frankreich: "#Frankreich",
    italien: "#Italien",
    niederlande: "#Niederlande",
    polen: "#Polen",
    spanien: "#Spanien",
    chile: "#Chile",
    finnland: "#Finnland",
    schweden: "#Schweden",
    MatGrp0000: "#Vollholz Nadelholz",
    MatGrp0001: "#Brettschichtholz homogen",
    MatGrp0002: "#Furnierschichtholz",
    MatGrp0003: "#Balkenschichtholz",
    MatGrp0004: "#mehrschichtige Massivholzplatte",
    MatGrp0005: "#xBaufurniersperrholz",
    MatGrp0006: "#Furnierschichtholz (alle Fasern parallel)",
    MatGrp0007: "#Kunstharzgebundene Holzspanplatten",
    MatGrp0008: "#Zementgebundene Holzspanplatten",
    MatGrp0009: "#Holzfaserplatten HB.HLA2",
    MatGrp0010: "#Holzfaserplatten MHB.LA2",
    MatGrp0012: "#Vollholz Laubholz",
    MatGrp0013: "#Brettschichtholz kombiniert",
    MatGrp0015: "#Brettsperrholz",
    MatGrp0020: "#OSB - Platten",
    MatGrp0021: "#Baufurniersperrholz",
    MatGrp0022: "#Gipskartonplatten (DIN 18180)",
    MatGrp0030: "#Pollmeier Bau-Buche",
    MatGrp1000: "#Stahl",
    MatGrp9100: "#Beton",
    MatGrp9999: "#Luft",
    Mat1000: "#Stahl S235JR",
    Mat1001: "#Stahl S355JR",
    Mat9999: "#----",
    Mat000: "#C14",
    Mat001: "#C16",
    Mat002: "#C18",
    Mat003: "#C20",
    Mat004: "#C22",
    Mat005: "#C24",
    Mat006: "#C27",
    Mat007: "#C30",
    Mat008: "#C35",
    Mat009: "#C40",
    Mat010: "#C45",
    Mat011: "#C50",
    Mat050: "#D30",
    Mat051: "#D35",
    Mat052: "#D40",
    Mat053: "#D50",
    Mat054: "#D60",
    Mat055: "#D70",
    Mat020: "#GL24h",
    Mat030: "#GL24c",
    Mat021: "#GL28h",
    Mat031: "#GL28c",
    Mat022: "#GL32h",
    Mat032: "#GL32c",
    Mat023: "#GL36h",
    Mat033: "#GL36c",
    Mat160: "#Kunstharzgeb. Spanpl Kl. P4 (EN 312)",
    Mat170: "#Kunstharzgeb. Spanpl Kl. P5 (EN 312)",
    Mat180: "#Kunstharzgeb. Spanpl Kl. P6 (EN 312)",
    Mat190: "#Kunstharzgeb. Spanpl Kl. P7 (EN 312)",
    Mat200: "#Zementgeb. Holzspanpl. Kl. 1+2 (EN 634)",
    Mat220: "#Holzfaserplatten HB.HLA2 (EN 622-2)",
    Mat230: "#Holzfaserplatten MBH.LA2 (EN 622-3)",
    Mat125: "#OSB/2 (EN 300)",
    Mat126: "#OSB/3 (EN 300)",
    Mat127: "#OSB/4 (EN 300)",
    Mat110: "#Sperrholz F25/10",
    Mat111: "#Sperrholz F40/40 E60/40",
    Mat112: "#Sperrholz F50/25 E70/25",
    Mat113: "#Sperrholz F60/10 E90/10",
    Mat520: "#Gipskartonplatten (DIN 18180)",
    Mat9120: "#C20/25",
    Mat9125: "#C25/30",
    Mat9130: "#C30/37",
    Mat9135: "#C35/45",
    Mat9140: "#C40/50",
    Mat9145: "#C45/55",
    Mat024: "#GL30h",
    Mat034: "#GL30c",
    Mat301: "#GL70",
    einheiten: "#Maße in",
    unskaliert: "#Darstellung nicht massstabsgetreu!",
    bauteile: "#Bauteile",
    festigkeit: "#Festigkeitsklasse",
    staendig: "#ständig",
    sehr_kurz: "#sehr kurz",
    lang: "#lang",
    mittel: "#mittel",
    led: "#Lasteinwirkungsdauer",
    hoehe: "#Höhe",
    kurz: "#kurz",
    schrauben: "#Schrauben",
    gewaehlt: "#Gewählt",
    breite: "#Breite",
    massstab: "#Schraubenabstand wird nicht immer massstabsgetreu gezeichnet!",
    lasteinwirkung: "#Lasteinwirkung (Bemessungslasten)",
    setztiefe: "#Setztiefe",
    vorgebohrt: "#Löcher vorgebohrt",
    SH: "#Stahl-Holz",
    scherkraft: "#Scherkraft",
    nicht_vorgebohrt: "#Löcher nicht vorgebohrt",
    scherfl_vbm: "#Scherflächen je Verbindungsmittel",
    scherfl2: "#2 Scherflächen",
    scherfl1: "#1 Scherfläche",
    modul_alt: "#M14 - Seitliche Trägerverstärkung",
    mat: "#Material 1",
    winkel: "#Winkel zwischen Bauteilen",
    schnittig2: "#2-schnittig",
    schnittig1: "#1-schnittig",
    geometrie: "#Geometrie",
    schichtfolge: "#Schichtfolge",
    wklscherkraft: "#Richtungswinkel der Scherkraft",
    modul: "#Schraubenbemessung - M03",
    scherfl_anschl: "#Scherfläche je Anschluss",
    mat1: "#Material 2",
    axialkraft: "#Axialkraft",
    hinweisLaubholz: "#Schrauben müssen bei der Anwendung in Laubholz vorgebohrt werden !",
    dicke: "#Dicke",
    raxtd: "#Bemessungswert der Zugtragfähigkeit",
    HH: "#Holz/HWS-Holz",
    rlatd: "#Bemessungswert der  Scherungtragfähigkeit",
    bauteil_1: "#Bauteil 1 (Bestand)",
    nachweis_traeger: "#Nachweis des Träger (Bestand)",
    einfeld: "#Einfeldträger",
    bestandundgeometrie: "#Bestand und Geometrie",
    L_system: "#Systemlänge",
    profilstahl: "#Profilstahl",
    holz: "#Holz",
    einwirkung_headline: "#Äußere Einwirkungen",
    schraubenpaare45: "#Schraubenpaare 45°",
    nachweis_verbinder: "#Nachweis Verbindungsmittel",
    richtung_2: "#Träger -> Verstärkung",
    richtung_1: "#Verstärkung -> Träger",
    profilklasse: "#Profilklasse",
    verstaerkung: "#Verstärkung",
    seitig2: "#2-seitig",
    vorlast: "#Vorbelastung (KLED=ständig)",
    seitig1: "#1-seitig",
    flachstahl: "#Flachstahl",
    stahlquerschnitt: "#Stahlquerschnitt",
    nachweis_verstaerkung: "#Nachweis der Verstärkung",
    charakteristischeLasten: "#Lasteinwirkung (Charakteristische Lasten)",
    ohne_bemessung: "#Ohne Bemessung",
    nachweis_gebrauchstauglichkeit: "#Gebrauchstauglichkeit",
    abstand_verstaerkung_auflager: "#Abstand der Verstärkung vom Auflager",
    schrauben45: "#Schrauben 45°",
    verstaerkungsmaterial: "#Verstärkungsmaterial",
    vortriebs_richtung: "#Vortriebs-Richtung",
    stahl_verstaerkung: "#Stahl-Verstärkung",
    bereichsweise_verstaerkung: "#Bereichsweise Verstärkung",
    verstaerkungsanordnung: "#Verstärkungsanordnung",
    holzverstaerkung: "#Holzverstärkung",
    schrauben90: "#Schrauben 90°",
    schraubenpaare: "#Schraubenpaare",
    einwirkung_2: "#Veränderliche Einwirkung 2",
    einwirkung_1: "#Veränderliche Einwirkung 1",
    staendige_Last: "#Ständige Einwirkungen",
    stahl: "#Stahl",
    systemabmessung: "#Systemabmessung",
    verbundstellen: "#Verbundstellen",
    lage_verbindungsmittel: "#Lage der Verbindungsmittel",
    headline: "#Tragfähigkeitsnachweis für seitlich verstärkten Träger",
    spitzboedenWohnraeume: "#A - Spitzböden, Wohn- und Aufenthaltsräume",
    eigenlastStaendig: "#Eigenlast/ständige Einwirkung",
    bueroflaechenArbeitsflaechen: "#B - Büroflächen, Arbeitsflächen, Flure",
    raeumeVersammlungsraeume: "#C - Räume, Versammlungsräume und Flächen zur Personenansammlung (ausser A, B, D und E)",
    verkaufsraeume: "#D - Verkaufsräume",
    lagerFabriken: "#E - Fabriken und Werkstätten, Ställe, Lagerräume und Zugänge, Flächen mit erheblichen Menschenansammlungen",
    verkehrsParkflaechen: "#F - Verkehrs- und Parkflächen für leichte Fahrzeuge (gesamtlast < 30 kN), Zufahrtsrampen",
    nichtBegehbareDaecher: "#H - nicht begehbare Dächer, ausser für übliche Erhaltungsmaßnahmen, Reparaturen",
    hubschrauberRegellasten: "#K - Hubschrauber Regellasten",
    treppenTreppenpodeste: "#T - Treppen und Treppenpodeste",
    zugaengeBalkone: "#Z - Zugänge, Balkone und Ähnliches",
    horizontaleNutzlasten: "#Horizontale Nutzlasten infolge von Personen unf Brüstungen, Geländer und anderen Konstruktionen, die als Absperrung dienen",
    windlastenNachNorm: "#Windlasten",
    schneeEisUnter1000: "#Schnee- und Eislast, Geländehöhe des Bauwerks üNN < 1000m",
    schneeEisUeber1000: "#Schnee- und Eislast, Geländehöhe des Bauwerks üNN > 1000m",
    grenzwerteGebrauchstauglichkeit: "#Grenzwerte Gebrauchstauglichkeit",
    schnittgroessen: "#Bemessungsschnittgrößen"
  };

});