define('m20/controllers/lasteinwirkung', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {
    debug: true,
    openFromFile: false,
    displayErrors: true,
    loading: false,
    bmlast: "",
    verbindungsmittelliste: "",
    nkl: 1,
    led: 1,

    ttBmLast: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "0.01 - 999.0 [kN]";
    }),
    i18n: Ember['default'].inject.service(),

    validations: {
      nkl: {
        type: "",
        tooltip: "ttNutzungsklasse",
        numericality: {
          greaterThanOrEqualTo: 1,
          lessThanOrEqualTo: 3
        }
      },
      bmlast: {
        numericality: {
          greaterThan: 0.01,
          lessThanOrEqualTo: 999.9
        }
      }
    },

    nklarray: [],

    ledarray: [],

    timberNA: "DE",

    init: function init() {
      this._super();
      this.setSelectFieldsContent();
    },

    setValues: function setValues(values) {
      if (self.debug) {
        console.log("values in lasteinwirkung: " + values);
      }

      this.set('openFromFile', true);

      this.set('nkl', parseInt(values.NKL_ID));
      this.set('led', parseInt(values.KLED_ID));
      this.set('bmlast', values.V_d);
      this.send('validation', values.V_d, { target: { name: "bmlast" } });

      this.set('openFromFile', false);
    },

    setSelectFieldsContent: function setSelectFieldsContent() {
      var self = this;
      self.setNklassen();
      self.setledarray();
    },

    setNklassen: function setNklassen() {
      // var self = this;
      // var indices = [1, 2];
      // self.set('nklarray', self.getSelectFieldContent('nklassen', indices));

      var self = this;
      var indices = [1, 2, 3];

      var maxNKLM1 = materialien.liste.findBy('IndexNr', self.controllerFor('bauteile').get('htfkl')).MaxNKL;
      var maxNKLM2 = materialien.liste.findBy('IndexNr', self.controllerFor('bauteile').get('ntfkl')).MaxNKL;

      var maxValues = [maxNKLM1, maxNKLM2];

      maxValues = maxValues.sort(function (a, b) {
        return a - b;
      });

      self.set('validations.nkl.numericality.lessThanOrEqualTo', maxValues[0]);

      self.set('nklarray', self.getSelectFieldContent('nklassen', indices, maxValues[0]));
    },

    setledarray: function setledarray() {
      var self = this;
      var indices = [];

      if (self.get('timberNA') === "DE") {
        indices = [1, 2, 3, 4, 45, 5];
      } else {
        if (self.get('led') === 45) {
          self.set('led', 1);
        }
        indices = [1, 2, 3, 4, 5];
      }

      self.set('ledarray', self.getSelectFieldContent('ledarray', indices));
    },

    getSelectFieldContent: function getSelectFieldContent(db, indexes) {
      var self = this;
      var tmp = [];
      var dbContent = self.getDBContent(db);
      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].id === indexes[i] && !(db === 'nklassen' && indexes[i] === 3)) {
            tmp.push({ id: dbContent[k].id, name: this.get('i18n').t(dbContent[k].name), disabled: dbContent[k].disabled });
          }
        }
      }

      return tmp;
    },

    getDBContent: function getDBContent(db) {
      var dbContent = [];
      switch (db) {
        case 'nklassen':
          dbContent = nutzungsklassenDB.nutzungsklassen_de;
          break;
        case 'ledarray':
          dbContent = ledDB.led_de;
          break;
      }

      return dbContent;
    },

    getValueFromSelectField: function getValueFromSelectField(contentArray, index) {
      var self = this;

      var tmp = "";
      var len = contentArray.length;

      for (var i = 0; i < len; i++) {
        if (contentArray[i].id === index) {
          tmp = contentArray[i].name;
        }
      }
      return tmp;
    },

    watchNumericalInputs: (function () {
      if (!this.openFromFile) {
        if (this.get('bmlast').indexOf(",") !== -1) {
          this.set('bmlast', this.get('bmlast').replace(",", "."));
        }
      }
    }).observes('bmlast'),

    watchComboboxes: (function () {

      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var lasteinwirkung = this.controllerFor('application').get('model').lasteinwirkung.objectAt(0);

      lasteinwirkung.set('NKL_ID', this.get('nkl'));

      var nklText = this.nklarray[this.get('nkl') - 1].name.toString();
      // var ledText = this.ledarray[this.get('led') - 1].name;

      lasteinwirkung.set('NKL_Text', nklText);
      lasteinwirkung.set('KLED_ID', this.get('led'));
      var ledText = this.ledarray.findBy('id', this.get('led')).name.toString();
      lasteinwirkung.set('LED_Text', ledText);
    }).observes('led', 'nkl'),

    setX3D: function setX3D(value, event) {

      var application = this.controllerFor('application');
      var m06default = application.get('model').m06default.objectAt(0);

      value = parseFloat(value).toFixed(1);

      switch (event.target.name) {
        case 'bmlast':
          if (this.errors.get('bmlast').length === 0) {
            m06default.set('bemessungslast', value);
          }
          break;
      }

      application.get('model').m06default.objectAt(0).set('ergebnisGeladen', false);
      application.get('model').m06default.objectAt(0).set('aktuellerSchraubenTyp', 'standardSchraube');
      application.get('model').m06default.objectAt(0).set('schraubenlisteAktualisiert', !application.get('model').m06default.objectAt(0).get('schraubenlisteAktualisiert'));
      application.get('model').m06default.objectAt(0).set('transformHelper', !application.get('model').m06default.objectAt(0).get('transformHelper'));
    },

    actions: {
      proceedInput: function proceedInput(event) {

        var self = this;

        var ledText = self.ledarray.findBy('id', this.get('led')).name.toString();
        // var ledText = self.ledarray[self.get('led') -1].name.toString();
        var nklText = self.nklarray[self.get('nkl') - 1].name.toString();

        var validation = self.get('isValid');

        if (validation === true) {

          var lasteinwirkung = self.store.createRecord('lasteinwirkung', {

            KLED_ID: self.get('led'),
            LED_Text: ledText,
            NKL_ID: self.get('nkl'),
            NKL_Text: nklText,
            V_d: self.get('bmlast').replace(",", ".")

          });
        }

        self.validate().then(function () {
          // all validations pass
          this.transitionToRoute('verbindungsmittel');
        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);
            applicationdata.set('pdfErstellt', false);

            var downloadcenter = self.controllerFor('downloadcenter');
            downloadcenter.set('pdfErstellt', false);

            application.set('verbindungsmittelInvalid', true);

            self.set('displayErrors', false);
            application.set('lasteinwirkungInvalid', false);

            //var applicationdata = application.model.objectAt(0);
            var applicationdata = application.get('model').application.objectAt(0);
            var applicationdataJSON = JSON.stringify(applicationdata);

            var projektdatendata = application.get('model').projektdaten.objectAt(0);
            var projektdatendataJSON = JSON.stringify(projektdatendata);

            // var bauteiledata = application.get('model').bauteile.objectAt(0);
            // var bauteiledataJSON = JSON.stringify(bauteiledata);
            //
            // var anschlussgeometriedata = application.get('model').anschlussgeometrie.objectAt(0);
            // var anschlussgeometriedataJSON = JSON.stringify(anschlussgeometriedata);
            //
            // var lasteinwirkungdata = application.get('model').lasteinwirkung.objectAt(0);
            // var lasteinwirkungdataJSON = JSON.stringify(lasteinwirkungdata);
            //
            // if (self.debug) {
            //   console.log("projektdatendata: " + projektdatendataJSON);
            //   console.log("applicationdataJSON: " + applicationdataJSON);
            //   console.log("bauteiledataJSON: " + bauteiledataJSON);
            //   console.log("anschlussgeometriedataJSON: " + anschlussgeometriedataJSON);
            //   console.log("lasteinwirkungdataJSON: " + lasteinwirkungdataJSON);
            // }
            //
            // var projektdatendataJSONcut = projektdatendataJSON.substring(1, projektdatendataJSON.length - 1);
            // var bauteiledataJSONcut = bauteiledataJSON.substring(1, bauteiledataJSON.length - 1);
            // var anschlussgeometriedataJSONcut = anschlussgeometriedataJSON.substring(1, anschlussgeometriedataJSON.length - 1);
            // var lasteinwirkungdataJSONcut = lasteinwirkungdataJSON.substring(1, lasteinwirkungdataJSON.length - 1);
            //
            // var separators = [':', ','];
            // if (self.debug) {
            //   console.log(separators.join('|'));
            // }
            // var bauteildataarray = bauteiledataJSONcut.split(new RegExp(separators.join('|'), 'g'));
            // console.log(bauteildataarray);
            //
            // var timberelementstringht = "\"MatKeyTimberElementHT\" :" + bauteildataarray[1] + ", \"FKLKeyTimberElementHT\" :" + bauteildataarray[3] + ", \"TimberElementHT\" : { \"Querschnittswerte\" : { \"b\" : " + bauteildataarray[5] + ",  \"h\": " + bauteildataarray[7] + " } },";
            // var timberelementstringnt = "\"MatKeyTimberElementNT\" :" + bauteildataarray[13] + ", \"FKLKeyTimberElementNT\" :" + bauteildataarray[15] + ", \"TimberElementNT\" : { \"Querschnittswerte\" : { \"b\" : " + bauteildataarray[17] + ",  \"h\": " + bauteildataarray[19] + " } }";
            //
            // var bauteileString = "\"Min_sSchrOK\" : " + bauteildataarray[25] + ", \"Min_sSchrUK\" : " + bauteildataarray[27] + ", \"Predrilled\" : " + bauteildataarray[29] + ", \"HtRiegel\" : " + bauteildataarray[11] + ", \"Torsionssteif\" : " + bauteildataarray[23];
            //
            // var JSONdata = "{" + bauteileString + "," + timberelementstringht + timberelementstringnt + "," + anschlussgeometriedataJSONcut + "," + lasteinwirkungdataJSONcut + "}";

            var JSONdata = self.controllerFor('supercontroller').uebergabedatenErzeugen();

            console.log('JSONdata');
            console.log(JSONdata);

            var vmittel = self.controllerFor('verbindungsmittel');
            vmittel.set('loading', true);

            self.set('loading', true);
            self.transitionToRoute('verbindungsmittel');

            var initialized = applicationdata.get('initialized');

            var server = application.get('server');
            var pfad = application.get('pfad');

            console.log('Server: ' + server);
            console.log('Pfad: ' + pfad);

            if (!initialized) {

              $.ajax({
                type: "POST",
                url: server + "initialisiereBerechnung/",
                //url: "http://136.243.4.143/berechnungsservice/m06/DE/erzeugeSchraubenliste/",
                data: {
                  kennung: applicationdataJSON,
                  // projektdaten: projektdatendataJSON,
                  paras: JSONdata,
                  modul: "m06",
                  pfad: pfad
                }
              }).done(function (data, statusText, xhr) {

                self.controllerFor('supercontroller').logaufruf("initialisiereBerechnung", xhr.status);

                console.log('data: ' + data);

                var cid = data;
                applicationdata.set('Calculation_ID', cid);
                applicationdata.set('initialized', true);

                $.ajax({
                  type: "POST",
                  url: server + "erzeugeSchraubenliste/",
                  data: {
                    cid: cid
                  }
                }).done(function (data, statusText, xhr) {

                  self.controllerFor('supercontroller').logaufruf("erzeugeSchraubenliste", xhr.status);

                  console.log(data);

                  if (Number(JSON.parse(data).Item1) === 0) {
                    var liste = JSON.parse(JSON.parse(data).Item2);
                    self.verbindungsmittelliste = liste;
                    var core = treetools.buildTree(liste, true);
                    vmittel.set('schraubenliste', core);
                    vmittel.set('keineSchraubenliste', false);
                  } else if (Number(JSON.parse(data).Item1) === 1) {
                    application.set('verbindungsmittelInvalid', false);
                    application.get('model').x3ddefault.objectAt(0).set('ergebnisGeladen', true);
                    vmittel.set('infoText', JSON.parse(data).Item3);
                    vmittel.set('keineSchraubenliste', true);
                  }

                  vmittel.set('calcid', cid);
                  vmittel.set('loading', false);
                  applicationdata.set('treeLoaded', true);

                  self.set('loading', false);
                });
              });
            } else {

              var cid = applicationdata.get('Calculation_ID');

              $.ajax({
                type: "POST",
                url: server + "erzeugeSchraubenliste/",
                data: {
                  cid: cid
                }
              }).done(function (data, statusText, xhr) {

                self.controllerFor('supercontroller').logaufruf("erzeugeSchraubenliste", xhr.status);

                console.log('DATA ' + data);

                if (Number(JSON.parse(data).Item1) === 0) {
                  var liste = JSON.parse(JSON.parse(data).Item2);
                  self.verbindungsmittelliste = liste;
                  var core = treetools.buildTree(liste, true);
                  vmittel.set('schraubenliste', core);
                  vmittel.set('keineSchraubenliste', false);
                } else if (Number(JSON.parse(data).Item1) === 1) {
                  application.set('verbindungsmittelInvalid', false);
                  application.get('model').x3ddefault.objectAt(0).set('ergebnisGeladen', true);
                  vmittel.set('infoText', JSON.parse(data).Item3);
                  vmittel.set('keineSchraubenliste', true);
                }

                vmittel.set('calcid', cid);
                vmittel.set('loading', false);
                applicationdata.set('treeLoaded', true);

                self.set('loading', false);
              });
            }
          } else {
            self.set('displayErrors', true);
            application.set('lasteinwirkungInvalid', true);
          }
        });
      },

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('lasteinwirkungInvalid', false);

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);

            application.set('verbindungsmittelInvalid', true);

            var lasteinwirkung = self.controllerFor('application').get('model').lasteinwirkung.objectAt(0);

            lasteinwirkung.set('V_d', parseFloat(self.get('bmlast')).toFixed(1));
            // self.set('bmlast', parseFloat(self.get('bmlast').replace(",", ".")).toFixed(1));
          } else {
              self.set('displayErrors', true);
              application.set('lasteinwirkungInvalid', true);
            }
        });
      },

      bmlastIsSelected: function bmlastIsSelected() {
        var bmlastaktiv = this.controllerFor('application').get('model').m06default.objectAt(0).get('bemessungslastAktiv');
        this.controllerFor('application').get('model').m06default.objectAt(0).set('bemessungslastAktiv', !bmlastaktiv);

        if (this.get('bmlast') !== "") {
          var bmlastwork = parseFloat(this.get('bmlast').replace(",", "."));
          var bmlastrounded = bmlastwork.toFixed(1);
          this.set('bmlast', bmlastrounded);
          this.send('validation', bmlastrounded, { target: { name: "bmlast" } });
        }

        if (bmlastaktiv === false) {
          document.getElementsByName('bmlast')[0].setSelectionRange(0, this.get('bmlast').length);
        }
      }

    }
  });

});