define('m20/routes/projektdaten', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend({

		model: function model() {
			return this.store.find('projektdaten');
		},

		setupController: function setupController() {
			this.controllerFor('application').miniertX3D();
		}

	});

});