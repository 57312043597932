define('m20/components/con-e', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Cone = Ember['default'].Component.extend({
    tagName: 'transform',
    layoutName: 'con-e',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: [''],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    translation: (function () {
      return '0 0 0';
    }).property(''),

    rotation: (function () {
      return '1 0 0 3.14159';
    }).property(''),

    coneTagID: (function () {
      return 'SpitzeLastenpfeil';
    }).property(''),

    coneBottomradius: (function () {
      return '0.075';
    }).property(''),

    coneHeight: (function () {
      return '0.2';
    }).property(''),

    emissivecolor: (function () {
      var name = this.get('name');
      var m06 = this.get('model');

      var emsvcolor = '0 0 0';

      if (m06.objectAt(0).get('bemessungslastAktiv') === true && name === 'lastenPfeil') {
        emsvcolor = '1 0 0';
      }

      return emsvcolor;
    }).property('model.firstObject.htbreiteAktiv', 'model.firstObject.hthoeheAktiv', 'model.firstObject.ntbreiteAktiv', 'model.firstObject.nthoeheAktiv', 'model.firstObject.hWinkelAktiv', 'model.firstObject.vWinkelAktiv', 'model.firstObject.bemessungslastAktiv')

  });

  exports['default'] = Cone;

});