define('m20/components/winkelbemassun-g', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'winkelbemassun-g',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    // center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",
    test: 1337,

    center: (function () {
      var values = '0 0 0';

      return values;
    }).property(''),

    translation: (function () {
      var name = this.get('name');
      var m06 = this.get('model');
      var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);
      var values = '0 0 0';

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt'),

    rotation: (function () {
      var name = this.get('name');
      var m06 = this.get('model').objectAt(0);
      var bauteile = m06.get('bauteile');
      var values;

      var winkel = Number(m06.get('vWinkel')) * Math.PI / 180;

      values = '0 0 1 ' + winkel;

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.schraubenGekreuzt')
  });

  exports['default'] = Transform;

});